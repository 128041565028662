@charset "UTF-8";
/* !****************************************************************
  common.scss
  Update: 2018/01/30
  @import　bootstrap-4.0.0
*******************************************************************/
/* !****************************************************************
  _variables.scss
  Update: 2018/01/23
  @import　bootstrap-4.0.0
*******************************************************************/
/*
  _mixins.scss
  Update: 2018/01/24
*/
/*
 * @extend %font-smoothing;
 */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
 * @include fs(16);
 */
/*
  _button.scss
  Update: 2018/01/23

  計算サイト　http://calc-site.com/triangles/side_height
*/
.menu-bt {
  background-color: #262626;
  border-radius: 5px;
  display: block;
  max-width: 50px;
  padding: 10px 10px 8px 10px;
  position: relative;
  z-index: 3;
}

.menu-bt.menu-hover {
  background-color: rgba(38, 38, 38, 0.7);
  text-decoration: none;
}

.menu-bt:focus, .menu-bt:hover {
  text-decoration: none;
}

.menu-bt .line-wrap {
  height: 25px;
  position: relative;
  width: 30px;
}

.menu-bt .line-wrap span {
  background-color: #fff;
  display: block;
  height: 2px;
  position: absolute;
  transition: all .4s;
  width: 100%;
}

.menu-bt .line-wrap .line1 {
  top: 0;
}

.menu-bt .line-wrap .line2 {
  margin-top: -1px;
  top: 50%;
}

.menu-bt .line-wrap .line3 {
  bottom: 0;
}

.menu-bt .menu-title {
  color: #fff;
  font-size: 10px;
  line-height: 1;
  margin-bottom: 0;
  margin-bottom: -2px;
  margin-top: 5px;
  text-align: center;
  transition: all .4s;
  word-wrap: normal;
}

.menu-bt.active {
  background-color: #262626;
}

.menu-bt.active.menu-hover {
  background-color: rgba(38, 38, 38, 0.7);
}

.menu-bt.active .line1 {
  transform: translateY(11.5px) rotate(39.8deg);
}

.menu-bt.active .line2 {
  opacity: 0;
}

.menu-bt.active .line3 {
  transform: translateY(-11.5px) rotate(-39.8deg);
}

/*
  _layout.scss
  Update: 2018/01/23
*/
.rsMenu-nav-wrap {
  background-color: #262626;
  bottom: 0;
  position: fixed;
  right: -240px;
  top: 0;
  transition: right 250ms ease-out;
  width: 240px;
  z-index: 9999;
}

.rsMenu-open .rsMenu-nav-wrap {
  right: 0;
}

.rsMenu-compliance {
  padding: 5px 10px;
  position: fixed;
  right: 0;
  top: 0;
  transition: right 250ms ease-out;
  z-index: 9999;
}

.rsMenu-open .rsMenu-compliance {
  right: 240px;
}

.rsMenu-open .rsMenu-compliance::before {
  border-color: transparent #262626 transparent transparent;
  border-style: solid;
  border-width: 7px 8px 7px 0;
  content: "";
  height: 0;
  margin-top: -7px;
  position: absolute;
  right: 0;
  top: 50%;
  width: 0;
}

.rsMenu-scroll {
  height: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  word-wrap: break-word;
}

.rsMenu-open {
  position: relative;
}

.rsMenu-open body {
  bottom: 0 !important;
  left: 0 !important;
  position: relative;
  right: 0 !important;
  top: 0 !important;
}

.rsMenu-close .rsMenu-overlay {
  visibility: hidden;
}

.rsMenu-close .rsMenu-nav-wrap {
  visibility: hidden;
}

.rsMenu-close.rsMenu-animating .rsMenu-overlay {
  visibility: visible;
}

.rsMenu-close.rsMenu-animating .rsMenu-nav-wrap {
  visibility: visible;
}

.rsMenu-overlay {
  background-color: #000;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 250ms;
  z-index: 9998;
}

.rsMenu-open .rsMenu-overlay {
  opacity: 0.6;
}

/*
  _DeviceTel.scss
  Update: 2018/01/24

  ※ jquery.ap_Utility.js
  <a href="tel:0963633632" class="tel-links">Tel:096-363-3632</a>
*/
.deviceTel-no-js .tel-links {
  pointer-events: none;
}

/*
  _flexBody.scss
  Update: 2018/01/24

  footerのflex下部固定。
  <div class="flex-body d-sm-flex flex-sm-column">
    <div class="flex-header">{% include layouts-header.html %}</div>
    <main class="flex-content">{{ content }}</main>
    <div class="flex-footer mt-auto">{% include layouts-footer.html %}</div>
  </div>
*/
@media (min-width: 576px) {
  .flex-body {
    min-height: 100vh;
  }
}

@media all and (-ms-high-contrast: none) {
  html,
  body,
  .flex-body {
    height: 100%;
  }
}

/* ****************************************************************
ImgMax.scss
Update: 2017/10/20
@import　bootstrap-4.0.0-beta
*******************************************************************/
.img-max {
  height: auto;
  width: 100%;
}

@media (max-width: 767.98px) {
  .img-max-sm-down {
    height: auto;
    max-width: none !important;
    width: 100% !important;
  }
}

@media (max-width: 575.98px) {
  .img-max-xs-down {
    height: auto;
    max-width: none !important;
    width: 100% !important;
  }
}

/*
  _typography.scss
  Update: 2018/01/24
*/
.fs-10 {
  font-size: 0.625rem;
}

.lead-10 {
  line-height: 1;
}

.fs-11 {
  font-size: 0.6875rem;
}

.lead-11 {
  line-height: 1.1;
}

.fs-12 {
  font-size: 0.75rem;
}

.lead-12 {
  line-height: 1.2;
}

.fs-13 {
  font-size: 0.8125rem;
}

.lead-13 {
  line-height: 1.3;
}

.fs-14 {
  font-size: 0.875rem;
}

.lead-14 {
  line-height: 1.4;
}

.fs-15 {
  font-size: 0.9375rem;
}

.lead-15 {
  line-height: 1.5;
}

.fs-16 {
  font-size: 1rem;
}

.lead-16 {
  line-height: 1.6;
}

.fs-17 {
  font-size: 1.0625rem;
}

.lead-17 {
  line-height: 1.7;
}

.fs-18 {
  font-size: 1.125rem;
}

.lead-18 {
  line-height: 1.8;
}

.fs-19 {
  font-size: 1.1875rem;
}

.lead-19 {
  line-height: 1.9;
}

.fs-20 {
  font-size: 1.25rem;
}

.lead-20 {
  line-height: 2;
}

.fs-21 {
  font-size: 1.3125rem;
}

.lead-21 {
  line-height: 2.1;
}

.fs-22 {
  font-size: 1.375rem;
}

.lead-22 {
  line-height: 2.2;
}

.fs-23 {
  font-size: 1.4375rem;
}

.lead-23 {
  line-height: 2.3;
}

.fs-24 {
  font-size: 1.5rem;
}

.lead-24 {
  line-height: 2.4;
}

.fs-25 {
  font-size: 1.5625rem;
}

.lead-25 {
  line-height: 2.5;
}

.fs-26 {
  font-size: 1.625rem;
}

.lead-26 {
  line-height: 2.6;
}

.fs-27 {
  font-size: 1.6875rem;
}

.lead-27 {
  line-height: 2.7;
}

.fs-28 {
  font-size: 1.75rem;
}

.lead-28 {
  line-height: 2.8;
}

.fs-29 {
  font-size: 1.8125rem;
}

.lead-29 {
  line-height: 2.9;
}

.fs-30 {
  font-size: 1.875rem;
}

.lead-30 {
  line-height: 3;
}

.fs-31 {
  font-size: 1.9375rem;
}

.lead-31 {
  line-height: 3.1;
}

.fs-32 {
  font-size: 2rem;
}

.lead-32 {
  line-height: 3.2;
}

.fs-33 {
  font-size: 2.0625rem;
}

.lead-33 {
  line-height: 3.3;
}

.fs-34 {
  font-size: 2.125rem;
}

.lead-34 {
  line-height: 3.4;
}

.fs-35 {
  font-size: 2.1875rem;
}

.lead-35 {
  line-height: 3.5;
}

.fs-36 {
  font-size: 2.25rem;
}

.lead-36 {
  line-height: 3.6;
}

.fs-37 {
  font-size: 2.3125rem;
}

.lead-37 {
  line-height: 3.7;
}

.fs-38 {
  font-size: 2.375rem;
}

.lead-38 {
  line-height: 3.8;
}

.fs-39 {
  font-size: 2.4375rem;
}

.lead-39 {
  line-height: 3.9;
}

.fs-40 {
  font-size: 2.5rem;
}

.lead-40 {
  line-height: 4;
}

.fs-41 {
  font-size: 2.5625rem;
}

.lead-41 {
  line-height: 4.1;
}

.fs-42 {
  font-size: 2.625rem;
}

.lead-42 {
  line-height: 4.2;
}

.fs-43 {
  font-size: 2.6875rem;
}

.lead-43 {
  line-height: 4.3;
}

.fs-44 {
  font-size: 2.75rem;
}

.lead-44 {
  line-height: 4.4;
}

.fs-45 {
  font-size: 2.8125rem;
}

.lead-45 {
  line-height: 4.5;
}

.lead-no {
  line-height: 0;
}

.gothic {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", Roboto, "Yu Gothic", YuGothic, Verdana, Meiryo, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.mincyo {
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
}

/*****************************************************************
  _anchor.scss
  Update: 2018/01/24
  <a href="">テキスト</a>
  <a href=""><u>テキスト</u></a>
  <a href="" class="underline">テキスト</a>
*******************************************************************/
/* stylelint-disable selector-no-qualifying-type */
a.underline {
  text-decoration: underline;
}

a.underline:hover, a.underline:focus {
  text-decoration: none;
}

a.no-underline:hover, a.no-underline:focus {
  text-decoration: none;
}

a:not(.no-opacity):not(.bk-img):not(.on_off):not(.touchend):hover > img, a:not(.no-opacity):not(.bk-img):not(.on_off):not(.touchend):focus > img {
  opacity: 0.6;
}

a.bk-img {
  display: inline-block;
  position: relative;
}

a.bk-img:not(.touchend):hover::before, a.bk-img:not(.touchend):focus::before {
  background-color: rgba(0, 0, 0, 0.4);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

/* stylelint-enable */
/*****************************************************************
  _reset.scss
  Update: 2018/01/24
*******************************************************************/
:root {
  font-size: calc(100vw / 23.4375);
}

@media (min-width: 376px) {
  :root {
    font-size: 16px;
  }
}

body {
  color: #262626;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", Roboto, "Yu Gothic", YuGothic, Verdana, Meiryo, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  text-rendering: optimizeLegibility;
  -webkit-touch-callout: none;
  word-wrap: break-word;
}

a {
  color: #990;
}

a:hover, a:focus {
  color: #4d4d00;
}

/*
//Headings
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: $headings-margin-bottom;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
}

h1, .h1 { font-size: $h1-font-size; }
h2, .h2 { font-size: $h2-font-size; }
h3, .h3 { font-size: $h3-font-size; }
h4, .h4 { font-size: $h4-font-size; }
h5, .h5 { font-size: $h5-font-size; }
h6, .h6 { font-size: $h6-font-size; }

// Type display classes
.display-1 {
  font-size: $display1-size;
  font-weight: $display1-weight;
  line-height: $display-line-height;
}
.display-2 {
  font-size: $display2-size;
  font-weight: $display2-weight;
  line-height: $display-line-height;
}
.display-3 {
  font-size: $display3-size;
  font-weight: $display3-weight;
  line-height: $display-line-height;
}
.display-4 {
  font-size: $display4-size;
  font-weight: $display4-weight;
  line-height: $display-line-height;
}
*/
/*****************************************************************
  _row.scss
  Update: 2018/01/24
*******************************************************************/
.row.row-10 {
  margin-left: -5px;
  margin-right: -5px;
}

.row.row-10 > .col,
.row.row-10 > [class*="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

.row.row-05 {
  margin-left: -2.5px;
  margin-right: -2.5px;
}

.row.row-05 > .col,
.row.row-05 > [class*="col-"] {
  padding-left: 2.5px;
  padding-right: 2.5px;
}

.row.row-15 {
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.row.row-15 > .col,
.row.row-15 > [class*="col-"] {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

@media (max-width: 575.98px) {
  .row.row-15-xs-down {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .row.row-15-xs-down > .col,
  .row.row-15-xs-down > [class*="col-"] {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
}

@media (max-width: 767.98px) {
  .row.row-15-sm-down {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .row.row-15-sm-down > .col,
  .row.row-15-sm-down > [class*="col-"] {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
}

@media (max-width: 991.98px) {
  .row.row-15-md-down {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .row.row-15-md-down > .col,
  .row.row-15-md-down > [class*="col-"] {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
}

/*****************************************************************
  _sizing.scss
  Update: 2017/08/25
*******************************************************************/
.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.w-100 {
  width: 100% !important;
}

.h-5 {
  height: 5% !important;
}

.h-10 {
  height: 10% !important;
}

.h-15 {
  height: 15% !important;
}

.h-20 {
  height: 20% !important;
}

.h-25 {
  height: 25% !important;
}

.h-30 {
  height: 30% !important;
}

.h-35 {
  height: 35% !important;
}

.h-40 {
  height: 40% !important;
}

.h-45 {
  height: 45% !important;
}

.h-50 {
  height: 50% !important;
}

.h-55 {
  height: 55% !important;
}

.h-60 {
  height: 60% !important;
}

.h-65 {
  height: 65% !important;
}

.h-70 {
  height: 70% !important;
}

.h-75 {
  height: 75% !important;
}

.h-80 {
  height: 80% !important;
}

.h-85 {
  height: 85% !important;
}

.h-90 {
  height: 90% !important;
}

.h-95 {
  height: 95% !important;
}

.h-100 {
  height: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

/*****************************************************************
_spacing.scss
Update: 2018/01/24
*******************************************************************/
/*
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3)
) !default;
*/
/* stylelint-disable declaration-colon-space-after */
.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.my-0 {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.m-05 {
  margin: 0.3125rem !important;
}

.mt-05 {
  margin-top: 0.3125rem !important;
}

.mr-05 {
  margin-right: 0.3125rem !important;
}

.mb-05 {
  margin-bottom: 0.3125rem !important;
}

.ml-05 {
  margin-left: 0.3125rem !important;
}

.mx-05 {
  margin-left: 0.3125rem !important;
  margin-right: 0.3125rem !important;
}

.my-05 {
  margin-bottom: 0.3125rem !important;
  margin-top: 0.3125rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.m-10 {
  margin: 0.625rem !important;
}

.mt-10 {
  margin-top: 0.625rem !important;
}

.mr-10 {
  margin-right: 0.625rem !important;
}

.mb-10 {
  margin-bottom: 0.625rem !important;
}

.ml-10 {
  margin-left: 0.625rem !important;
}

.mx-10 {
  margin-left: 0.625rem !important;
  margin-right: 0.625rem !important;
}

.my-10 {
  margin-bottom: 0.625rem !important;
  margin-top: 0.625rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.m-15 {
  margin: 0.9375rem !important;
}

.mt-15 {
  margin-top: 0.9375rem !important;
}

.mr-15 {
  margin-right: 0.9375rem !important;
}

.mb-15 {
  margin-bottom: 0.9375rem !important;
}

.ml-15 {
  margin-left: 0.9375rem !important;
}

.mx-15 {
  margin-left: 0.9375rem !important;
  margin-right: 0.9375rem !important;
}

.my-15 {
  margin-bottom: 0.9375rem !important;
  margin-top: 0.9375rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.m-20 {
  margin: 1.25rem !important;
}

.mt-20 {
  margin-top: 1.25rem !important;
}

.mr-20 {
  margin-right: 1.25rem !important;
}

.mb-20 {
  margin-bottom: 1.25rem !important;
}

.ml-20 {
  margin-left: 1.25rem !important;
}

.mx-20 {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}

.my-20 {
  margin-bottom: 1.25rem !important;
  margin-top: 1.25rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.m-25 {
  margin: 1.5625rem !important;
}

.mt-25 {
  margin-top: 1.5625rem !important;
}

.mr-25 {
  margin-right: 1.5625rem !important;
}

.mb-25 {
  margin-bottom: 1.5625rem !important;
}

.ml-25 {
  margin-left: 1.5625rem !important;
}

.mx-25 {
  margin-left: 1.5625rem !important;
  margin-right: 1.5625rem !important;
}

.my-25 {
  margin-bottom: 1.5625rem !important;
  margin-top: 1.5625rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.m-30 {
  margin: 1.875rem !important;
}

.mt-30 {
  margin-top: 1.875rem !important;
}

.mr-30 {
  margin-right: 1.875rem !important;
}

.mb-30 {
  margin-bottom: 1.875rem !important;
}

.ml-30 {
  margin-left: 1.875rem !important;
}

.mx-30 {
  margin-left: 1.875rem !important;
  margin-right: 1.875rem !important;
}

.my-30 {
  margin-bottom: 1.875rem !important;
  margin-top: 1.875rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.m-35 {
  margin: 2.1875rem !important;
}

.mt-35 {
  margin-top: 2.1875rem !important;
}

.mr-35 {
  margin-right: 2.1875rem !important;
}

.mb-35 {
  margin-bottom: 2.1875rem !important;
}

.ml-35 {
  margin-left: 2.1875rem !important;
}

.mx-35 {
  margin-left: 2.1875rem !important;
  margin-right: 2.1875rem !important;
}

.my-35 {
  margin-bottom: 2.1875rem !important;
  margin-top: 2.1875rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.m-40 {
  margin: 2.5rem !important;
}

.mt-40 {
  margin-top: 2.5rem !important;
}

.mr-40 {
  margin-right: 2.5rem !important;
}

.mb-40 {
  margin-bottom: 2.5rem !important;
}

.ml-40 {
  margin-left: 2.5rem !important;
}

.mx-40 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}

.my-40 {
  margin-bottom: 2.5rem !important;
  margin-top: 2.5rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.m-45 {
  margin: 2.8125rem !important;
}

.mt-45 {
  margin-top: 2.8125rem !important;
}

.mr-45 {
  margin-right: 2.8125rem !important;
}

.mb-45 {
  margin-bottom: 2.8125rem !important;
}

.ml-45 {
  margin-left: 2.8125rem !important;
}

.mx-45 {
  margin-left: 2.8125rem !important;
  margin-right: 2.8125rem !important;
}

.my-45 {
  margin-bottom: 2.8125rem !important;
  margin-top: 2.8125rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.m-50 {
  margin: 3.125rem !important;
}

.mt-50 {
  margin-top: 3.125rem !important;
}

.mr-50 {
  margin-right: 3.125rem !important;
}

.mb-50 {
  margin-bottom: 3.125rem !important;
}

.ml-50 {
  margin-left: 3.125rem !important;
}

.mx-50 {
  margin-left: 3.125rem !important;
  margin-right: 3.125rem !important;
}

.my-50 {
  margin-bottom: 3.125rem !important;
  margin-top: 3.125rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.m-55 {
  margin: 3.4375rem !important;
}

.mt-55 {
  margin-top: 3.4375rem !important;
}

.mr-55 {
  margin-right: 3.4375rem !important;
}

.mb-55 {
  margin-bottom: 3.4375rem !important;
}

.ml-55 {
  margin-left: 3.4375rem !important;
}

.mx-55 {
  margin-left: 3.4375rem !important;
  margin-right: 3.4375rem !important;
}

.my-55 {
  margin-bottom: 3.4375rem !important;
  margin-top: 3.4375rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.m-60 {
  margin: 3.75rem !important;
}

.mt-60 {
  margin-top: 3.75rem !important;
}

.mr-60 {
  margin-right: 3.75rem !important;
}

.mb-60 {
  margin-bottom: 3.75rem !important;
}

.ml-60 {
  margin-left: 3.75rem !important;
}

.mx-60 {
  margin-left: 3.75rem !important;
  margin-right: 3.75rem !important;
}

.my-60 {
  margin-bottom: 3.75rem !important;
  margin-top: 3.75rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.m-65 {
  margin: 4.0625rem !important;
}

.mt-65 {
  margin-top: 4.0625rem !important;
}

.mr-65 {
  margin-right: 4.0625rem !important;
}

.mb-65 {
  margin-bottom: 4.0625rem !important;
}

.ml-65 {
  margin-left: 4.0625rem !important;
}

.mx-65 {
  margin-left: 4.0625rem !important;
  margin-right: 4.0625rem !important;
}

.my-65 {
  margin-bottom: 4.0625rem !important;
  margin-top: 4.0625rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.m-70 {
  margin: 4.375rem !important;
}

.mt-70 {
  margin-top: 4.375rem !important;
}

.mr-70 {
  margin-right: 4.375rem !important;
}

.mb-70 {
  margin-bottom: 4.375rem !important;
}

.ml-70 {
  margin-left: 4.375rem !important;
}

.mx-70 {
  margin-left: 4.375rem !important;
  margin-right: 4.375rem !important;
}

.my-70 {
  margin-bottom: 4.375rem !important;
  margin-top: 4.375rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.m-75 {
  margin: 4.6875rem !important;
}

.mt-75 {
  margin-top: 4.6875rem !important;
}

.mr-75 {
  margin-right: 4.6875rem !important;
}

.mb-75 {
  margin-bottom: 4.6875rem !important;
}

.ml-75 {
  margin-left: 4.6875rem !important;
}

.mx-75 {
  margin-left: 4.6875rem !important;
  margin-right: 4.6875rem !important;
}

.my-75 {
  margin-bottom: 4.6875rem !important;
  margin-top: 4.6875rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.m-80 {
  margin: 5rem !important;
}

.mt-80 {
  margin-top: 5rem !important;
}

.mr-80 {
  margin-right: 5rem !important;
}

.mb-80 {
  margin-bottom: 5rem !important;
}

.ml-80 {
  margin-left: 5rem !important;
}

.mx-80 {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}

.my-80 {
  margin-bottom: 5rem !important;
  margin-top: 5rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.m-85 {
  margin: 5.3125rem !important;
}

.mt-85 {
  margin-top: 5.3125rem !important;
}

.mr-85 {
  margin-right: 5.3125rem !important;
}

.mb-85 {
  margin-bottom: 5.3125rem !important;
}

.ml-85 {
  margin-left: 5.3125rem !important;
}

.mx-85 {
  margin-left: 5.3125rem !important;
  margin-right: 5.3125rem !important;
}

.my-85 {
  margin-bottom: 5.3125rem !important;
  margin-top: 5.3125rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.m-90 {
  margin: 5.625rem !important;
}

.mt-90 {
  margin-top: 5.625rem !important;
}

.mr-90 {
  margin-right: 5.625rem !important;
}

.mb-90 {
  margin-bottom: 5.625rem !important;
}

.ml-90 {
  margin-left: 5.625rem !important;
}

.mx-90 {
  margin-left: 5.625rem !important;
  margin-right: 5.625rem !important;
}

.my-90 {
  margin-bottom: 5.625rem !important;
  margin-top: 5.625rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.m-95 {
  margin: 5.9375rem !important;
}

.mt-95 {
  margin-top: 5.9375rem !important;
}

.mr-95 {
  margin-right: 5.9375rem !important;
}

.mb-95 {
  margin-bottom: 5.9375rem !important;
}

.ml-95 {
  margin-left: 5.9375rem !important;
}

.mx-95 {
  margin-left: 5.9375rem !important;
  margin-right: 5.9375rem !important;
}

.my-95 {
  margin-bottom: 5.9375rem !important;
  margin-top: 5.9375rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.m-100 {
  margin: 6.25rem !important;
}

.mt-100 {
  margin-top: 6.25rem !important;
}

.mr-100 {
  margin-right: 6.25rem !important;
}

.mb-100 {
  margin-bottom: 6.25rem !important;
}

.ml-100 {
  margin-left: 6.25rem !important;
}

.mx-100 {
  margin-left: 6.25rem !important;
  margin-right: 6.25rem !important;
}

.my-100 {
  margin-bottom: 6.25rem !important;
  margin-top: 6.25rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.py-0 {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.p-05 {
  padding: 0.3125rem !important;
}

.pt-05 {
  padding-top: 0.3125rem !important;
}

.pr-05 {
  padding-right: 0.3125rem !important;
}

.pb-05 {
  padding-bottom: 0.3125rem !important;
}

.pl-05 {
  padding-left: 0.3125rem !important;
}

.px-05 {
  padding-left: 0.3125rem !important;
  padding-right: 0.3125rem !important;
}

.py-05 {
  padding-bottom: 0.3125rem !important;
  padding-top: 0.3125rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.p-10 {
  padding: 0.625rem !important;
}

.pt-10 {
  padding-top: 0.625rem !important;
}

.pr-10 {
  padding-right: 0.625rem !important;
}

.pb-10 {
  padding-bottom: 0.625rem !important;
}

.pl-10 {
  padding-left: 0.625rem !important;
}

.px-10 {
  padding-left: 0.625rem !important;
  padding-right: 0.625rem !important;
}

.py-10 {
  padding-bottom: 0.625rem !important;
  padding-top: 0.625rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.p-15 {
  padding: 0.9375rem !important;
}

.pt-15 {
  padding-top: 0.9375rem !important;
}

.pr-15 {
  padding-right: 0.9375rem !important;
}

.pb-15 {
  padding-bottom: 0.9375rem !important;
}

.pl-15 {
  padding-left: 0.9375rem !important;
}

.px-15 {
  padding-left: 0.9375rem !important;
  padding-right: 0.9375rem !important;
}

.py-15 {
  padding-bottom: 0.9375rem !important;
  padding-top: 0.9375rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.p-20 {
  padding: 1.25rem !important;
}

.pt-20 {
  padding-top: 1.25rem !important;
}

.pr-20 {
  padding-right: 1.25rem !important;
}

.pb-20 {
  padding-bottom: 1.25rem !important;
}

.pl-20 {
  padding-left: 1.25rem !important;
}

.px-20 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.py-20 {
  padding-bottom: 1.25rem !important;
  padding-top: 1.25rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.p-25 {
  padding: 1.5625rem !important;
}

.pt-25 {
  padding-top: 1.5625rem !important;
}

.pr-25 {
  padding-right: 1.5625rem !important;
}

.pb-25 {
  padding-bottom: 1.5625rem !important;
}

.pl-25 {
  padding-left: 1.5625rem !important;
}

.px-25 {
  padding-left: 1.5625rem !important;
  padding-right: 1.5625rem !important;
}

.py-25 {
  padding-bottom: 1.5625rem !important;
  padding-top: 1.5625rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.p-30 {
  padding: 1.875rem !important;
}

.pt-30 {
  padding-top: 1.875rem !important;
}

.pr-30 {
  padding-right: 1.875rem !important;
}

.pb-30 {
  padding-bottom: 1.875rem !important;
}

.pl-30 {
  padding-left: 1.875rem !important;
}

.px-30 {
  padding-left: 1.875rem !important;
  padding-right: 1.875rem !important;
}

.py-30 {
  padding-bottom: 1.875rem !important;
  padding-top: 1.875rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.p-35 {
  padding: 2.1875rem !important;
}

.pt-35 {
  padding-top: 2.1875rem !important;
}

.pr-35 {
  padding-right: 2.1875rem !important;
}

.pb-35 {
  padding-bottom: 2.1875rem !important;
}

.pl-35 {
  padding-left: 2.1875rem !important;
}

.px-35 {
  padding-left: 2.1875rem !important;
  padding-right: 2.1875rem !important;
}

.py-35 {
  padding-bottom: 2.1875rem !important;
  padding-top: 2.1875rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.p-40 {
  padding: 2.5rem !important;
}

.pt-40 {
  padding-top: 2.5rem !important;
}

.pr-40 {
  padding-right: 2.5rem !important;
}

.pb-40 {
  padding-bottom: 2.5rem !important;
}

.pl-40 {
  padding-left: 2.5rem !important;
}

.px-40 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.py-40 {
  padding-bottom: 2.5rem !important;
  padding-top: 2.5rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.p-45 {
  padding: 2.8125rem !important;
}

.pt-45 {
  padding-top: 2.8125rem !important;
}

.pr-45 {
  padding-right: 2.8125rem !important;
}

.pb-45 {
  padding-bottom: 2.8125rem !important;
}

.pl-45 {
  padding-left: 2.8125rem !important;
}

.px-45 {
  padding-left: 2.8125rem !important;
  padding-right: 2.8125rem !important;
}

.py-45 {
  padding-bottom: 2.8125rem !important;
  padding-top: 2.8125rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.p-50 {
  padding: 3.125rem !important;
}

.pt-50 {
  padding-top: 3.125rem !important;
}

.pr-50 {
  padding-right: 3.125rem !important;
}

.pb-50 {
  padding-bottom: 3.125rem !important;
}

.pl-50 {
  padding-left: 3.125rem !important;
}

.px-50 {
  padding-left: 3.125rem !important;
  padding-right: 3.125rem !important;
}

.py-50 {
  padding-bottom: 3.125rem !important;
  padding-top: 3.125rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.p-55 {
  padding: 3.4375rem !important;
}

.pt-55 {
  padding-top: 3.4375rem !important;
}

.pr-55 {
  padding-right: 3.4375rem !important;
}

.pb-55 {
  padding-bottom: 3.4375rem !important;
}

.pl-55 {
  padding-left: 3.4375rem !important;
}

.px-55 {
  padding-left: 3.4375rem !important;
  padding-right: 3.4375rem !important;
}

.py-55 {
  padding-bottom: 3.4375rem !important;
  padding-top: 3.4375rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.p-60 {
  padding: 3.75rem !important;
}

.pt-60 {
  padding-top: 3.75rem !important;
}

.pr-60 {
  padding-right: 3.75rem !important;
}

.pb-60 {
  padding-bottom: 3.75rem !important;
}

.pl-60 {
  padding-left: 3.75rem !important;
}

.px-60 {
  padding-left: 3.75rem !important;
  padding-right: 3.75rem !important;
}

.py-60 {
  padding-bottom: 3.75rem !important;
  padding-top: 3.75rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.p-65 {
  padding: 4.0625rem !important;
}

.pt-65 {
  padding-top: 4.0625rem !important;
}

.pr-65 {
  padding-right: 4.0625rem !important;
}

.pb-65 {
  padding-bottom: 4.0625rem !important;
}

.pl-65 {
  padding-left: 4.0625rem !important;
}

.px-65 {
  padding-left: 4.0625rem !important;
  padding-right: 4.0625rem !important;
}

.py-65 {
  padding-bottom: 4.0625rem !important;
  padding-top: 4.0625rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.p-70 {
  padding: 4.375rem !important;
}

.pt-70 {
  padding-top: 4.375rem !important;
}

.pr-70 {
  padding-right: 4.375rem !important;
}

.pb-70 {
  padding-bottom: 4.375rem !important;
}

.pl-70 {
  padding-left: 4.375rem !important;
}

.px-70 {
  padding-left: 4.375rem !important;
  padding-right: 4.375rem !important;
}

.py-70 {
  padding-bottom: 4.375rem !important;
  padding-top: 4.375rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.p-75 {
  padding: 4.6875rem !important;
}

.pt-75 {
  padding-top: 4.6875rem !important;
}

.pr-75 {
  padding-right: 4.6875rem !important;
}

.pb-75 {
  padding-bottom: 4.6875rem !important;
}

.pl-75 {
  padding-left: 4.6875rem !important;
}

.px-75 {
  padding-left: 4.6875rem !important;
  padding-right: 4.6875rem !important;
}

.py-75 {
  padding-bottom: 4.6875rem !important;
  padding-top: 4.6875rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.p-80 {
  padding: 5rem !important;
}

.pt-80 {
  padding-top: 5rem !important;
}

.pr-80 {
  padding-right: 5rem !important;
}

.pb-80 {
  padding-bottom: 5rem !important;
}

.pl-80 {
  padding-left: 5rem !important;
}

.px-80 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.py-80 {
  padding-bottom: 5rem !important;
  padding-top: 5rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.p-85 {
  padding: 5.3125rem !important;
}

.pt-85 {
  padding-top: 5.3125rem !important;
}

.pr-85 {
  padding-right: 5.3125rem !important;
}

.pb-85 {
  padding-bottom: 5.3125rem !important;
}

.pl-85 {
  padding-left: 5.3125rem !important;
}

.px-85 {
  padding-left: 5.3125rem !important;
  padding-right: 5.3125rem !important;
}

.py-85 {
  padding-bottom: 5.3125rem !important;
  padding-top: 5.3125rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.p-90 {
  padding: 5.625rem !important;
}

.pt-90 {
  padding-top: 5.625rem !important;
}

.pr-90 {
  padding-right: 5.625rem !important;
}

.pb-90 {
  padding-bottom: 5.625rem !important;
}

.pl-90 {
  padding-left: 5.625rem !important;
}

.px-90 {
  padding-left: 5.625rem !important;
  padding-right: 5.625rem !important;
}

.py-90 {
  padding-bottom: 5.625rem !important;
  padding-top: 5.625rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.p-95 {
  padding: 5.9375rem !important;
}

.pt-95 {
  padding-top: 5.9375rem !important;
}

.pr-95 {
  padding-right: 5.9375rem !important;
}

.pb-95 {
  padding-bottom: 5.9375rem !important;
}

.pl-95 {
  padding-left: 5.9375rem !important;
}

.px-95 {
  padding-left: 5.9375rem !important;
  padding-right: 5.9375rem !important;
}

.py-95 {
  padding-bottom: 5.9375rem !important;
  padding-top: 5.9375rem !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-colon-space-after */
.p-100 {
  padding: 6.25rem !important;
}

.pt-100 {
  padding-top: 6.25rem !important;
}

.pr-100 {
  padding-right: 6.25rem !important;
}

.pb-100 {
  padding-bottom: 6.25rem !important;
}

.pl-100 {
  padding-left: 6.25rem !important;
}

.px-100 {
  padding-left: 6.25rem !important;
  padding-right: 6.25rem !important;
}

.py-100 {
  padding-bottom: 6.25rem !important;
  padding-top: 6.25rem !important;
}

/* stylelint-enable */
@media (min-width: 576px) {
  /* stylelint-disable declaration-colon-space-after */
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0 {
    margin-left: 0 !important;
  }
  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .my-sm-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-sm-05 {
    margin: 0.3125rem !important;
  }
  .mt-sm-05 {
    margin-top: 0.3125rem !important;
  }
  .mr-sm-05 {
    margin-right: 0.3125rem !important;
  }
  .mb-sm-05 {
    margin-bottom: 0.3125rem !important;
  }
  .ml-sm-05 {
    margin-left: 0.3125rem !important;
  }
  .mx-sm-05 {
    margin-left: 0.3125rem !important;
    margin-right: 0.3125rem !important;
  }
  .my-sm-05 {
    margin-bottom: 0.3125rem !important;
    margin-top: 0.3125rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-sm-10 {
    margin: 0.625rem !important;
  }
  .mt-sm-10 {
    margin-top: 0.625rem !important;
  }
  .mr-sm-10 {
    margin-right: 0.625rem !important;
  }
  .mb-sm-10 {
    margin-bottom: 0.625rem !important;
  }
  .ml-sm-10 {
    margin-left: 0.625rem !important;
  }
  .mx-sm-10 {
    margin-left: 0.625rem !important;
    margin-right: 0.625rem !important;
  }
  .my-sm-10 {
    margin-bottom: 0.625rem !important;
    margin-top: 0.625rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-sm-15 {
    margin: 0.9375rem !important;
  }
  .mt-sm-15 {
    margin-top: 0.9375rem !important;
  }
  .mr-sm-15 {
    margin-right: 0.9375rem !important;
  }
  .mb-sm-15 {
    margin-bottom: 0.9375rem !important;
  }
  .ml-sm-15 {
    margin-left: 0.9375rem !important;
  }
  .mx-sm-15 {
    margin-left: 0.9375rem !important;
    margin-right: 0.9375rem !important;
  }
  .my-sm-15 {
    margin-bottom: 0.9375rem !important;
    margin-top: 0.9375rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-sm-20 {
    margin: 1.25rem !important;
  }
  .mt-sm-20 {
    margin-top: 1.25rem !important;
  }
  .mr-sm-20 {
    margin-right: 1.25rem !important;
  }
  .mb-sm-20 {
    margin-bottom: 1.25rem !important;
  }
  .ml-sm-20 {
    margin-left: 1.25rem !important;
  }
  .mx-sm-20 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }
  .my-sm-20 {
    margin-bottom: 1.25rem !important;
    margin-top: 1.25rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-sm-25 {
    margin: 1.5625rem !important;
  }
  .mt-sm-25 {
    margin-top: 1.5625rem !important;
  }
  .mr-sm-25 {
    margin-right: 1.5625rem !important;
  }
  .mb-sm-25 {
    margin-bottom: 1.5625rem !important;
  }
  .ml-sm-25 {
    margin-left: 1.5625rem !important;
  }
  .mx-sm-25 {
    margin-left: 1.5625rem !important;
    margin-right: 1.5625rem !important;
  }
  .my-sm-25 {
    margin-bottom: 1.5625rem !important;
    margin-top: 1.5625rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-sm-30 {
    margin: 1.875rem !important;
  }
  .mt-sm-30 {
    margin-top: 1.875rem !important;
  }
  .mr-sm-30 {
    margin-right: 1.875rem !important;
  }
  .mb-sm-30 {
    margin-bottom: 1.875rem !important;
  }
  .ml-sm-30 {
    margin-left: 1.875rem !important;
  }
  .mx-sm-30 {
    margin-left: 1.875rem !important;
    margin-right: 1.875rem !important;
  }
  .my-sm-30 {
    margin-bottom: 1.875rem !important;
    margin-top: 1.875rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-sm-35 {
    margin: 2.1875rem !important;
  }
  .mt-sm-35 {
    margin-top: 2.1875rem !important;
  }
  .mr-sm-35 {
    margin-right: 2.1875rem !important;
  }
  .mb-sm-35 {
    margin-bottom: 2.1875rem !important;
  }
  .ml-sm-35 {
    margin-left: 2.1875rem !important;
  }
  .mx-sm-35 {
    margin-left: 2.1875rem !important;
    margin-right: 2.1875rem !important;
  }
  .my-sm-35 {
    margin-bottom: 2.1875rem !important;
    margin-top: 2.1875rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-sm-40 {
    margin: 2.5rem !important;
  }
  .mt-sm-40 {
    margin-top: 2.5rem !important;
  }
  .mr-sm-40 {
    margin-right: 2.5rem !important;
  }
  .mb-sm-40 {
    margin-bottom: 2.5rem !important;
  }
  .ml-sm-40 {
    margin-left: 2.5rem !important;
  }
  .mx-sm-40 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .my-sm-40 {
    margin-bottom: 2.5rem !important;
    margin-top: 2.5rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-sm-45 {
    margin: 2.8125rem !important;
  }
  .mt-sm-45 {
    margin-top: 2.8125rem !important;
  }
  .mr-sm-45 {
    margin-right: 2.8125rem !important;
  }
  .mb-sm-45 {
    margin-bottom: 2.8125rem !important;
  }
  .ml-sm-45 {
    margin-left: 2.8125rem !important;
  }
  .mx-sm-45 {
    margin-left: 2.8125rem !important;
    margin-right: 2.8125rem !important;
  }
  .my-sm-45 {
    margin-bottom: 2.8125rem !important;
    margin-top: 2.8125rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-sm-50 {
    margin: 3.125rem !important;
  }
  .mt-sm-50 {
    margin-top: 3.125rem !important;
  }
  .mr-sm-50 {
    margin-right: 3.125rem !important;
  }
  .mb-sm-50 {
    margin-bottom: 3.125rem !important;
  }
  .ml-sm-50 {
    margin-left: 3.125rem !important;
  }
  .mx-sm-50 {
    margin-left: 3.125rem !important;
    margin-right: 3.125rem !important;
  }
  .my-sm-50 {
    margin-bottom: 3.125rem !important;
    margin-top: 3.125rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-sm-55 {
    margin: 3.4375rem !important;
  }
  .mt-sm-55 {
    margin-top: 3.4375rem !important;
  }
  .mr-sm-55 {
    margin-right: 3.4375rem !important;
  }
  .mb-sm-55 {
    margin-bottom: 3.4375rem !important;
  }
  .ml-sm-55 {
    margin-left: 3.4375rem !important;
  }
  .mx-sm-55 {
    margin-left: 3.4375rem !important;
    margin-right: 3.4375rem !important;
  }
  .my-sm-55 {
    margin-bottom: 3.4375rem !important;
    margin-top: 3.4375rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-sm-60 {
    margin: 3.75rem !important;
  }
  .mt-sm-60 {
    margin-top: 3.75rem !important;
  }
  .mr-sm-60 {
    margin-right: 3.75rem !important;
  }
  .mb-sm-60 {
    margin-bottom: 3.75rem !important;
  }
  .ml-sm-60 {
    margin-left: 3.75rem !important;
  }
  .mx-sm-60 {
    margin-left: 3.75rem !important;
    margin-right: 3.75rem !important;
  }
  .my-sm-60 {
    margin-bottom: 3.75rem !important;
    margin-top: 3.75rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-sm-65 {
    margin: 4.0625rem !important;
  }
  .mt-sm-65 {
    margin-top: 4.0625rem !important;
  }
  .mr-sm-65 {
    margin-right: 4.0625rem !important;
  }
  .mb-sm-65 {
    margin-bottom: 4.0625rem !important;
  }
  .ml-sm-65 {
    margin-left: 4.0625rem !important;
  }
  .mx-sm-65 {
    margin-left: 4.0625rem !important;
    margin-right: 4.0625rem !important;
  }
  .my-sm-65 {
    margin-bottom: 4.0625rem !important;
    margin-top: 4.0625rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-sm-70 {
    margin: 4.375rem !important;
  }
  .mt-sm-70 {
    margin-top: 4.375rem !important;
  }
  .mr-sm-70 {
    margin-right: 4.375rem !important;
  }
  .mb-sm-70 {
    margin-bottom: 4.375rem !important;
  }
  .ml-sm-70 {
    margin-left: 4.375rem !important;
  }
  .mx-sm-70 {
    margin-left: 4.375rem !important;
    margin-right: 4.375rem !important;
  }
  .my-sm-70 {
    margin-bottom: 4.375rem !important;
    margin-top: 4.375rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-sm-75 {
    margin: 4.6875rem !important;
  }
  .mt-sm-75 {
    margin-top: 4.6875rem !important;
  }
  .mr-sm-75 {
    margin-right: 4.6875rem !important;
  }
  .mb-sm-75 {
    margin-bottom: 4.6875rem !important;
  }
  .ml-sm-75 {
    margin-left: 4.6875rem !important;
  }
  .mx-sm-75 {
    margin-left: 4.6875rem !important;
    margin-right: 4.6875rem !important;
  }
  .my-sm-75 {
    margin-bottom: 4.6875rem !important;
    margin-top: 4.6875rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-sm-80 {
    margin: 5rem !important;
  }
  .mt-sm-80 {
    margin-top: 5rem !important;
  }
  .mr-sm-80 {
    margin-right: 5rem !important;
  }
  .mb-sm-80 {
    margin-bottom: 5rem !important;
  }
  .ml-sm-80 {
    margin-left: 5rem !important;
  }
  .mx-sm-80 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .my-sm-80 {
    margin-bottom: 5rem !important;
    margin-top: 5rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-sm-85 {
    margin: 5.3125rem !important;
  }
  .mt-sm-85 {
    margin-top: 5.3125rem !important;
  }
  .mr-sm-85 {
    margin-right: 5.3125rem !important;
  }
  .mb-sm-85 {
    margin-bottom: 5.3125rem !important;
  }
  .ml-sm-85 {
    margin-left: 5.3125rem !important;
  }
  .mx-sm-85 {
    margin-left: 5.3125rem !important;
    margin-right: 5.3125rem !important;
  }
  .my-sm-85 {
    margin-bottom: 5.3125rem !important;
    margin-top: 5.3125rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-sm-90 {
    margin: 5.625rem !important;
  }
  .mt-sm-90 {
    margin-top: 5.625rem !important;
  }
  .mr-sm-90 {
    margin-right: 5.625rem !important;
  }
  .mb-sm-90 {
    margin-bottom: 5.625rem !important;
  }
  .ml-sm-90 {
    margin-left: 5.625rem !important;
  }
  .mx-sm-90 {
    margin-left: 5.625rem !important;
    margin-right: 5.625rem !important;
  }
  .my-sm-90 {
    margin-bottom: 5.625rem !important;
    margin-top: 5.625rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-sm-95 {
    margin: 5.9375rem !important;
  }
  .mt-sm-95 {
    margin-top: 5.9375rem !important;
  }
  .mr-sm-95 {
    margin-right: 5.9375rem !important;
  }
  .mb-sm-95 {
    margin-bottom: 5.9375rem !important;
  }
  .ml-sm-95 {
    margin-left: 5.9375rem !important;
  }
  .mx-sm-95 {
    margin-left: 5.9375rem !important;
    margin-right: 5.9375rem !important;
  }
  .my-sm-95 {
    margin-bottom: 5.9375rem !important;
    margin-top: 5.9375rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-sm-100 {
    margin: 6.25rem !important;
  }
  .mt-sm-100 {
    margin-top: 6.25rem !important;
  }
  .mr-sm-100 {
    margin-right: 6.25rem !important;
  }
  .mb-sm-100 {
    margin-bottom: 6.25rem !important;
  }
  .ml-sm-100 {
    margin-left: 6.25rem !important;
  }
  .mx-sm-100 {
    margin-left: 6.25rem !important;
    margin-right: 6.25rem !important;
  }
  .my-sm-100 {
    margin-bottom: 6.25rem !important;
    margin-top: 6.25rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0 {
    padding-left: 0 !important;
  }
  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .py-sm-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-sm-05 {
    padding: 0.3125rem !important;
  }
  .pt-sm-05 {
    padding-top: 0.3125rem !important;
  }
  .pr-sm-05 {
    padding-right: 0.3125rem !important;
  }
  .pb-sm-05 {
    padding-bottom: 0.3125rem !important;
  }
  .pl-sm-05 {
    padding-left: 0.3125rem !important;
  }
  .px-sm-05 {
    padding-left: 0.3125rem !important;
    padding-right: 0.3125rem !important;
  }
  .py-sm-05 {
    padding-bottom: 0.3125rem !important;
    padding-top: 0.3125rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-sm-10 {
    padding: 0.625rem !important;
  }
  .pt-sm-10 {
    padding-top: 0.625rem !important;
  }
  .pr-sm-10 {
    padding-right: 0.625rem !important;
  }
  .pb-sm-10 {
    padding-bottom: 0.625rem !important;
  }
  .pl-sm-10 {
    padding-left: 0.625rem !important;
  }
  .px-sm-10 {
    padding-left: 0.625rem !important;
    padding-right: 0.625rem !important;
  }
  .py-sm-10 {
    padding-bottom: 0.625rem !important;
    padding-top: 0.625rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-sm-15 {
    padding: 0.9375rem !important;
  }
  .pt-sm-15 {
    padding-top: 0.9375rem !important;
  }
  .pr-sm-15 {
    padding-right: 0.9375rem !important;
  }
  .pb-sm-15 {
    padding-bottom: 0.9375rem !important;
  }
  .pl-sm-15 {
    padding-left: 0.9375rem !important;
  }
  .px-sm-15 {
    padding-left: 0.9375rem !important;
    padding-right: 0.9375rem !important;
  }
  .py-sm-15 {
    padding-bottom: 0.9375rem !important;
    padding-top: 0.9375rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-sm-20 {
    padding: 1.25rem !important;
  }
  .pt-sm-20 {
    padding-top: 1.25rem !important;
  }
  .pr-sm-20 {
    padding-right: 1.25rem !important;
  }
  .pb-sm-20 {
    padding-bottom: 1.25rem !important;
  }
  .pl-sm-20 {
    padding-left: 1.25rem !important;
  }
  .px-sm-20 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  .py-sm-20 {
    padding-bottom: 1.25rem !important;
    padding-top: 1.25rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-sm-25 {
    padding: 1.5625rem !important;
  }
  .pt-sm-25 {
    padding-top: 1.5625rem !important;
  }
  .pr-sm-25 {
    padding-right: 1.5625rem !important;
  }
  .pb-sm-25 {
    padding-bottom: 1.5625rem !important;
  }
  .pl-sm-25 {
    padding-left: 1.5625rem !important;
  }
  .px-sm-25 {
    padding-left: 1.5625rem !important;
    padding-right: 1.5625rem !important;
  }
  .py-sm-25 {
    padding-bottom: 1.5625rem !important;
    padding-top: 1.5625rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-sm-30 {
    padding: 1.875rem !important;
  }
  .pt-sm-30 {
    padding-top: 1.875rem !important;
  }
  .pr-sm-30 {
    padding-right: 1.875rem !important;
  }
  .pb-sm-30 {
    padding-bottom: 1.875rem !important;
  }
  .pl-sm-30 {
    padding-left: 1.875rem !important;
  }
  .px-sm-30 {
    padding-left: 1.875rem !important;
    padding-right: 1.875rem !important;
  }
  .py-sm-30 {
    padding-bottom: 1.875rem !important;
    padding-top: 1.875rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-sm-35 {
    padding: 2.1875rem !important;
  }
  .pt-sm-35 {
    padding-top: 2.1875rem !important;
  }
  .pr-sm-35 {
    padding-right: 2.1875rem !important;
  }
  .pb-sm-35 {
    padding-bottom: 2.1875rem !important;
  }
  .pl-sm-35 {
    padding-left: 2.1875rem !important;
  }
  .px-sm-35 {
    padding-left: 2.1875rem !important;
    padding-right: 2.1875rem !important;
  }
  .py-sm-35 {
    padding-bottom: 2.1875rem !important;
    padding-top: 2.1875rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-sm-40 {
    padding: 2.5rem !important;
  }
  .pt-sm-40 {
    padding-top: 2.5rem !important;
  }
  .pr-sm-40 {
    padding-right: 2.5rem !important;
  }
  .pb-sm-40 {
    padding-bottom: 2.5rem !important;
  }
  .pl-sm-40 {
    padding-left: 2.5rem !important;
  }
  .px-sm-40 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .py-sm-40 {
    padding-bottom: 2.5rem !important;
    padding-top: 2.5rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-sm-45 {
    padding: 2.8125rem !important;
  }
  .pt-sm-45 {
    padding-top: 2.8125rem !important;
  }
  .pr-sm-45 {
    padding-right: 2.8125rem !important;
  }
  .pb-sm-45 {
    padding-bottom: 2.8125rem !important;
  }
  .pl-sm-45 {
    padding-left: 2.8125rem !important;
  }
  .px-sm-45 {
    padding-left: 2.8125rem !important;
    padding-right: 2.8125rem !important;
  }
  .py-sm-45 {
    padding-bottom: 2.8125rem !important;
    padding-top: 2.8125rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-sm-50 {
    padding: 3.125rem !important;
  }
  .pt-sm-50 {
    padding-top: 3.125rem !important;
  }
  .pr-sm-50 {
    padding-right: 3.125rem !important;
  }
  .pb-sm-50 {
    padding-bottom: 3.125rem !important;
  }
  .pl-sm-50 {
    padding-left: 3.125rem !important;
  }
  .px-sm-50 {
    padding-left: 3.125rem !important;
    padding-right: 3.125rem !important;
  }
  .py-sm-50 {
    padding-bottom: 3.125rem !important;
    padding-top: 3.125rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-sm-55 {
    padding: 3.4375rem !important;
  }
  .pt-sm-55 {
    padding-top: 3.4375rem !important;
  }
  .pr-sm-55 {
    padding-right: 3.4375rem !important;
  }
  .pb-sm-55 {
    padding-bottom: 3.4375rem !important;
  }
  .pl-sm-55 {
    padding-left: 3.4375rem !important;
  }
  .px-sm-55 {
    padding-left: 3.4375rem !important;
    padding-right: 3.4375rem !important;
  }
  .py-sm-55 {
    padding-bottom: 3.4375rem !important;
    padding-top: 3.4375rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-sm-60 {
    padding: 3.75rem !important;
  }
  .pt-sm-60 {
    padding-top: 3.75rem !important;
  }
  .pr-sm-60 {
    padding-right: 3.75rem !important;
  }
  .pb-sm-60 {
    padding-bottom: 3.75rem !important;
  }
  .pl-sm-60 {
    padding-left: 3.75rem !important;
  }
  .px-sm-60 {
    padding-left: 3.75rem !important;
    padding-right: 3.75rem !important;
  }
  .py-sm-60 {
    padding-bottom: 3.75rem !important;
    padding-top: 3.75rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-sm-65 {
    padding: 4.0625rem !important;
  }
  .pt-sm-65 {
    padding-top: 4.0625rem !important;
  }
  .pr-sm-65 {
    padding-right: 4.0625rem !important;
  }
  .pb-sm-65 {
    padding-bottom: 4.0625rem !important;
  }
  .pl-sm-65 {
    padding-left: 4.0625rem !important;
  }
  .px-sm-65 {
    padding-left: 4.0625rem !important;
    padding-right: 4.0625rem !important;
  }
  .py-sm-65 {
    padding-bottom: 4.0625rem !important;
    padding-top: 4.0625rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-sm-70 {
    padding: 4.375rem !important;
  }
  .pt-sm-70 {
    padding-top: 4.375rem !important;
  }
  .pr-sm-70 {
    padding-right: 4.375rem !important;
  }
  .pb-sm-70 {
    padding-bottom: 4.375rem !important;
  }
  .pl-sm-70 {
    padding-left: 4.375rem !important;
  }
  .px-sm-70 {
    padding-left: 4.375rem !important;
    padding-right: 4.375rem !important;
  }
  .py-sm-70 {
    padding-bottom: 4.375rem !important;
    padding-top: 4.375rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-sm-75 {
    padding: 4.6875rem !important;
  }
  .pt-sm-75 {
    padding-top: 4.6875rem !important;
  }
  .pr-sm-75 {
    padding-right: 4.6875rem !important;
  }
  .pb-sm-75 {
    padding-bottom: 4.6875rem !important;
  }
  .pl-sm-75 {
    padding-left: 4.6875rem !important;
  }
  .px-sm-75 {
    padding-left: 4.6875rem !important;
    padding-right: 4.6875rem !important;
  }
  .py-sm-75 {
    padding-bottom: 4.6875rem !important;
    padding-top: 4.6875rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-sm-80 {
    padding: 5rem !important;
  }
  .pt-sm-80 {
    padding-top: 5rem !important;
  }
  .pr-sm-80 {
    padding-right: 5rem !important;
  }
  .pb-sm-80 {
    padding-bottom: 5rem !important;
  }
  .pl-sm-80 {
    padding-left: 5rem !important;
  }
  .px-sm-80 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .py-sm-80 {
    padding-bottom: 5rem !important;
    padding-top: 5rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-sm-85 {
    padding: 5.3125rem !important;
  }
  .pt-sm-85 {
    padding-top: 5.3125rem !important;
  }
  .pr-sm-85 {
    padding-right: 5.3125rem !important;
  }
  .pb-sm-85 {
    padding-bottom: 5.3125rem !important;
  }
  .pl-sm-85 {
    padding-left: 5.3125rem !important;
  }
  .px-sm-85 {
    padding-left: 5.3125rem !important;
    padding-right: 5.3125rem !important;
  }
  .py-sm-85 {
    padding-bottom: 5.3125rem !important;
    padding-top: 5.3125rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-sm-90 {
    padding: 5.625rem !important;
  }
  .pt-sm-90 {
    padding-top: 5.625rem !important;
  }
  .pr-sm-90 {
    padding-right: 5.625rem !important;
  }
  .pb-sm-90 {
    padding-bottom: 5.625rem !important;
  }
  .pl-sm-90 {
    padding-left: 5.625rem !important;
  }
  .px-sm-90 {
    padding-left: 5.625rem !important;
    padding-right: 5.625rem !important;
  }
  .py-sm-90 {
    padding-bottom: 5.625rem !important;
    padding-top: 5.625rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-sm-95 {
    padding: 5.9375rem !important;
  }
  .pt-sm-95 {
    padding-top: 5.9375rem !important;
  }
  .pr-sm-95 {
    padding-right: 5.9375rem !important;
  }
  .pb-sm-95 {
    padding-bottom: 5.9375rem !important;
  }
  .pl-sm-95 {
    padding-left: 5.9375rem !important;
  }
  .px-sm-95 {
    padding-left: 5.9375rem !important;
    padding-right: 5.9375rem !important;
  }
  .py-sm-95 {
    padding-bottom: 5.9375rem !important;
    padding-top: 5.9375rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-sm-100 {
    padding: 6.25rem !important;
  }
  .pt-sm-100 {
    padding-top: 6.25rem !important;
  }
  .pr-sm-100 {
    padding-right: 6.25rem !important;
  }
  .pb-sm-100 {
    padding-bottom: 6.25rem !important;
  }
  .pl-sm-100 {
    padding-left: 6.25rem !important;
  }
  .px-sm-100 {
    padding-left: 6.25rem !important;
    padding-right: 6.25rem !important;
  }
  .py-sm-100 {
    padding-bottom: 6.25rem !important;
    padding-top: 6.25rem !important;
  }
  /* stylelint-enable */
}

@media (min-width: 768px) {
  /* stylelint-disable declaration-colon-space-after */
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0 {
    margin-left: 0 !important;
  }
  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .my-md-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-md-05 {
    margin: 0.3125rem !important;
  }
  .mt-md-05 {
    margin-top: 0.3125rem !important;
  }
  .mr-md-05 {
    margin-right: 0.3125rem !important;
  }
  .mb-md-05 {
    margin-bottom: 0.3125rem !important;
  }
  .ml-md-05 {
    margin-left: 0.3125rem !important;
  }
  .mx-md-05 {
    margin-left: 0.3125rem !important;
    margin-right: 0.3125rem !important;
  }
  .my-md-05 {
    margin-bottom: 0.3125rem !important;
    margin-top: 0.3125rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-md-10 {
    margin: 0.625rem !important;
  }
  .mt-md-10 {
    margin-top: 0.625rem !important;
  }
  .mr-md-10 {
    margin-right: 0.625rem !important;
  }
  .mb-md-10 {
    margin-bottom: 0.625rem !important;
  }
  .ml-md-10 {
    margin-left: 0.625rem !important;
  }
  .mx-md-10 {
    margin-left: 0.625rem !important;
    margin-right: 0.625rem !important;
  }
  .my-md-10 {
    margin-bottom: 0.625rem !important;
    margin-top: 0.625rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-md-15 {
    margin: 0.9375rem !important;
  }
  .mt-md-15 {
    margin-top: 0.9375rem !important;
  }
  .mr-md-15 {
    margin-right: 0.9375rem !important;
  }
  .mb-md-15 {
    margin-bottom: 0.9375rem !important;
  }
  .ml-md-15 {
    margin-left: 0.9375rem !important;
  }
  .mx-md-15 {
    margin-left: 0.9375rem !important;
    margin-right: 0.9375rem !important;
  }
  .my-md-15 {
    margin-bottom: 0.9375rem !important;
    margin-top: 0.9375rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-md-20 {
    margin: 1.25rem !important;
  }
  .mt-md-20 {
    margin-top: 1.25rem !important;
  }
  .mr-md-20 {
    margin-right: 1.25rem !important;
  }
  .mb-md-20 {
    margin-bottom: 1.25rem !important;
  }
  .ml-md-20 {
    margin-left: 1.25rem !important;
  }
  .mx-md-20 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }
  .my-md-20 {
    margin-bottom: 1.25rem !important;
    margin-top: 1.25rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-md-25 {
    margin: 1.5625rem !important;
  }
  .mt-md-25 {
    margin-top: 1.5625rem !important;
  }
  .mr-md-25 {
    margin-right: 1.5625rem !important;
  }
  .mb-md-25 {
    margin-bottom: 1.5625rem !important;
  }
  .ml-md-25 {
    margin-left: 1.5625rem !important;
  }
  .mx-md-25 {
    margin-left: 1.5625rem !important;
    margin-right: 1.5625rem !important;
  }
  .my-md-25 {
    margin-bottom: 1.5625rem !important;
    margin-top: 1.5625rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-md-30 {
    margin: 1.875rem !important;
  }
  .mt-md-30 {
    margin-top: 1.875rem !important;
  }
  .mr-md-30 {
    margin-right: 1.875rem !important;
  }
  .mb-md-30 {
    margin-bottom: 1.875rem !important;
  }
  .ml-md-30 {
    margin-left: 1.875rem !important;
  }
  .mx-md-30 {
    margin-left: 1.875rem !important;
    margin-right: 1.875rem !important;
  }
  .my-md-30 {
    margin-bottom: 1.875rem !important;
    margin-top: 1.875rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-md-35 {
    margin: 2.1875rem !important;
  }
  .mt-md-35 {
    margin-top: 2.1875rem !important;
  }
  .mr-md-35 {
    margin-right: 2.1875rem !important;
  }
  .mb-md-35 {
    margin-bottom: 2.1875rem !important;
  }
  .ml-md-35 {
    margin-left: 2.1875rem !important;
  }
  .mx-md-35 {
    margin-left: 2.1875rem !important;
    margin-right: 2.1875rem !important;
  }
  .my-md-35 {
    margin-bottom: 2.1875rem !important;
    margin-top: 2.1875rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-md-40 {
    margin: 2.5rem !important;
  }
  .mt-md-40 {
    margin-top: 2.5rem !important;
  }
  .mr-md-40 {
    margin-right: 2.5rem !important;
  }
  .mb-md-40 {
    margin-bottom: 2.5rem !important;
  }
  .ml-md-40 {
    margin-left: 2.5rem !important;
  }
  .mx-md-40 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .my-md-40 {
    margin-bottom: 2.5rem !important;
    margin-top: 2.5rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-md-45 {
    margin: 2.8125rem !important;
  }
  .mt-md-45 {
    margin-top: 2.8125rem !important;
  }
  .mr-md-45 {
    margin-right: 2.8125rem !important;
  }
  .mb-md-45 {
    margin-bottom: 2.8125rem !important;
  }
  .ml-md-45 {
    margin-left: 2.8125rem !important;
  }
  .mx-md-45 {
    margin-left: 2.8125rem !important;
    margin-right: 2.8125rem !important;
  }
  .my-md-45 {
    margin-bottom: 2.8125rem !important;
    margin-top: 2.8125rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-md-50 {
    margin: 3.125rem !important;
  }
  .mt-md-50 {
    margin-top: 3.125rem !important;
  }
  .mr-md-50 {
    margin-right: 3.125rem !important;
  }
  .mb-md-50 {
    margin-bottom: 3.125rem !important;
  }
  .ml-md-50 {
    margin-left: 3.125rem !important;
  }
  .mx-md-50 {
    margin-left: 3.125rem !important;
    margin-right: 3.125rem !important;
  }
  .my-md-50 {
    margin-bottom: 3.125rem !important;
    margin-top: 3.125rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-md-55 {
    margin: 3.4375rem !important;
  }
  .mt-md-55 {
    margin-top: 3.4375rem !important;
  }
  .mr-md-55 {
    margin-right: 3.4375rem !important;
  }
  .mb-md-55 {
    margin-bottom: 3.4375rem !important;
  }
  .ml-md-55 {
    margin-left: 3.4375rem !important;
  }
  .mx-md-55 {
    margin-left: 3.4375rem !important;
    margin-right: 3.4375rem !important;
  }
  .my-md-55 {
    margin-bottom: 3.4375rem !important;
    margin-top: 3.4375rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-md-60 {
    margin: 3.75rem !important;
  }
  .mt-md-60 {
    margin-top: 3.75rem !important;
  }
  .mr-md-60 {
    margin-right: 3.75rem !important;
  }
  .mb-md-60 {
    margin-bottom: 3.75rem !important;
  }
  .ml-md-60 {
    margin-left: 3.75rem !important;
  }
  .mx-md-60 {
    margin-left: 3.75rem !important;
    margin-right: 3.75rem !important;
  }
  .my-md-60 {
    margin-bottom: 3.75rem !important;
    margin-top: 3.75rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-md-65 {
    margin: 4.0625rem !important;
  }
  .mt-md-65 {
    margin-top: 4.0625rem !important;
  }
  .mr-md-65 {
    margin-right: 4.0625rem !important;
  }
  .mb-md-65 {
    margin-bottom: 4.0625rem !important;
  }
  .ml-md-65 {
    margin-left: 4.0625rem !important;
  }
  .mx-md-65 {
    margin-left: 4.0625rem !important;
    margin-right: 4.0625rem !important;
  }
  .my-md-65 {
    margin-bottom: 4.0625rem !important;
    margin-top: 4.0625rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-md-70 {
    margin: 4.375rem !important;
  }
  .mt-md-70 {
    margin-top: 4.375rem !important;
  }
  .mr-md-70 {
    margin-right: 4.375rem !important;
  }
  .mb-md-70 {
    margin-bottom: 4.375rem !important;
  }
  .ml-md-70 {
    margin-left: 4.375rem !important;
  }
  .mx-md-70 {
    margin-left: 4.375rem !important;
    margin-right: 4.375rem !important;
  }
  .my-md-70 {
    margin-bottom: 4.375rem !important;
    margin-top: 4.375rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-md-75 {
    margin: 4.6875rem !important;
  }
  .mt-md-75 {
    margin-top: 4.6875rem !important;
  }
  .mr-md-75 {
    margin-right: 4.6875rem !important;
  }
  .mb-md-75 {
    margin-bottom: 4.6875rem !important;
  }
  .ml-md-75 {
    margin-left: 4.6875rem !important;
  }
  .mx-md-75 {
    margin-left: 4.6875rem !important;
    margin-right: 4.6875rem !important;
  }
  .my-md-75 {
    margin-bottom: 4.6875rem !important;
    margin-top: 4.6875rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-md-80 {
    margin: 5rem !important;
  }
  .mt-md-80 {
    margin-top: 5rem !important;
  }
  .mr-md-80 {
    margin-right: 5rem !important;
  }
  .mb-md-80 {
    margin-bottom: 5rem !important;
  }
  .ml-md-80 {
    margin-left: 5rem !important;
  }
  .mx-md-80 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .my-md-80 {
    margin-bottom: 5rem !important;
    margin-top: 5rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-md-85 {
    margin: 5.3125rem !important;
  }
  .mt-md-85 {
    margin-top: 5.3125rem !important;
  }
  .mr-md-85 {
    margin-right: 5.3125rem !important;
  }
  .mb-md-85 {
    margin-bottom: 5.3125rem !important;
  }
  .ml-md-85 {
    margin-left: 5.3125rem !important;
  }
  .mx-md-85 {
    margin-left: 5.3125rem !important;
    margin-right: 5.3125rem !important;
  }
  .my-md-85 {
    margin-bottom: 5.3125rem !important;
    margin-top: 5.3125rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-md-90 {
    margin: 5.625rem !important;
  }
  .mt-md-90 {
    margin-top: 5.625rem !important;
  }
  .mr-md-90 {
    margin-right: 5.625rem !important;
  }
  .mb-md-90 {
    margin-bottom: 5.625rem !important;
  }
  .ml-md-90 {
    margin-left: 5.625rem !important;
  }
  .mx-md-90 {
    margin-left: 5.625rem !important;
    margin-right: 5.625rem !important;
  }
  .my-md-90 {
    margin-bottom: 5.625rem !important;
    margin-top: 5.625rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-md-95 {
    margin: 5.9375rem !important;
  }
  .mt-md-95 {
    margin-top: 5.9375rem !important;
  }
  .mr-md-95 {
    margin-right: 5.9375rem !important;
  }
  .mb-md-95 {
    margin-bottom: 5.9375rem !important;
  }
  .ml-md-95 {
    margin-left: 5.9375rem !important;
  }
  .mx-md-95 {
    margin-left: 5.9375rem !important;
    margin-right: 5.9375rem !important;
  }
  .my-md-95 {
    margin-bottom: 5.9375rem !important;
    margin-top: 5.9375rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-md-100 {
    margin: 6.25rem !important;
  }
  .mt-md-100 {
    margin-top: 6.25rem !important;
  }
  .mr-md-100 {
    margin-right: 6.25rem !important;
  }
  .mb-md-100 {
    margin-bottom: 6.25rem !important;
  }
  .ml-md-100 {
    margin-left: 6.25rem !important;
  }
  .mx-md-100 {
    margin-left: 6.25rem !important;
    margin-right: 6.25rem !important;
  }
  .my-md-100 {
    margin-bottom: 6.25rem !important;
    margin-top: 6.25rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0 {
    padding-left: 0 !important;
  }
  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .py-md-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-md-05 {
    padding: 0.3125rem !important;
  }
  .pt-md-05 {
    padding-top: 0.3125rem !important;
  }
  .pr-md-05 {
    padding-right: 0.3125rem !important;
  }
  .pb-md-05 {
    padding-bottom: 0.3125rem !important;
  }
  .pl-md-05 {
    padding-left: 0.3125rem !important;
  }
  .px-md-05 {
    padding-left: 0.3125rem !important;
    padding-right: 0.3125rem !important;
  }
  .py-md-05 {
    padding-bottom: 0.3125rem !important;
    padding-top: 0.3125rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-md-10 {
    padding: 0.625rem !important;
  }
  .pt-md-10 {
    padding-top: 0.625rem !important;
  }
  .pr-md-10 {
    padding-right: 0.625rem !important;
  }
  .pb-md-10 {
    padding-bottom: 0.625rem !important;
  }
  .pl-md-10 {
    padding-left: 0.625rem !important;
  }
  .px-md-10 {
    padding-left: 0.625rem !important;
    padding-right: 0.625rem !important;
  }
  .py-md-10 {
    padding-bottom: 0.625rem !important;
    padding-top: 0.625rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-md-15 {
    padding: 0.9375rem !important;
  }
  .pt-md-15 {
    padding-top: 0.9375rem !important;
  }
  .pr-md-15 {
    padding-right: 0.9375rem !important;
  }
  .pb-md-15 {
    padding-bottom: 0.9375rem !important;
  }
  .pl-md-15 {
    padding-left: 0.9375rem !important;
  }
  .px-md-15 {
    padding-left: 0.9375rem !important;
    padding-right: 0.9375rem !important;
  }
  .py-md-15 {
    padding-bottom: 0.9375rem !important;
    padding-top: 0.9375rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-md-20 {
    padding: 1.25rem !important;
  }
  .pt-md-20 {
    padding-top: 1.25rem !important;
  }
  .pr-md-20 {
    padding-right: 1.25rem !important;
  }
  .pb-md-20 {
    padding-bottom: 1.25rem !important;
  }
  .pl-md-20 {
    padding-left: 1.25rem !important;
  }
  .px-md-20 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  .py-md-20 {
    padding-bottom: 1.25rem !important;
    padding-top: 1.25rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-md-25 {
    padding: 1.5625rem !important;
  }
  .pt-md-25 {
    padding-top: 1.5625rem !important;
  }
  .pr-md-25 {
    padding-right: 1.5625rem !important;
  }
  .pb-md-25 {
    padding-bottom: 1.5625rem !important;
  }
  .pl-md-25 {
    padding-left: 1.5625rem !important;
  }
  .px-md-25 {
    padding-left: 1.5625rem !important;
    padding-right: 1.5625rem !important;
  }
  .py-md-25 {
    padding-bottom: 1.5625rem !important;
    padding-top: 1.5625rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-md-30 {
    padding: 1.875rem !important;
  }
  .pt-md-30 {
    padding-top: 1.875rem !important;
  }
  .pr-md-30 {
    padding-right: 1.875rem !important;
  }
  .pb-md-30 {
    padding-bottom: 1.875rem !important;
  }
  .pl-md-30 {
    padding-left: 1.875rem !important;
  }
  .px-md-30 {
    padding-left: 1.875rem !important;
    padding-right: 1.875rem !important;
  }
  .py-md-30 {
    padding-bottom: 1.875rem !important;
    padding-top: 1.875rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-md-35 {
    padding: 2.1875rem !important;
  }
  .pt-md-35 {
    padding-top: 2.1875rem !important;
  }
  .pr-md-35 {
    padding-right: 2.1875rem !important;
  }
  .pb-md-35 {
    padding-bottom: 2.1875rem !important;
  }
  .pl-md-35 {
    padding-left: 2.1875rem !important;
  }
  .px-md-35 {
    padding-left: 2.1875rem !important;
    padding-right: 2.1875rem !important;
  }
  .py-md-35 {
    padding-bottom: 2.1875rem !important;
    padding-top: 2.1875rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-md-40 {
    padding: 2.5rem !important;
  }
  .pt-md-40 {
    padding-top: 2.5rem !important;
  }
  .pr-md-40 {
    padding-right: 2.5rem !important;
  }
  .pb-md-40 {
    padding-bottom: 2.5rem !important;
  }
  .pl-md-40 {
    padding-left: 2.5rem !important;
  }
  .px-md-40 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .py-md-40 {
    padding-bottom: 2.5rem !important;
    padding-top: 2.5rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-md-45 {
    padding: 2.8125rem !important;
  }
  .pt-md-45 {
    padding-top: 2.8125rem !important;
  }
  .pr-md-45 {
    padding-right: 2.8125rem !important;
  }
  .pb-md-45 {
    padding-bottom: 2.8125rem !important;
  }
  .pl-md-45 {
    padding-left: 2.8125rem !important;
  }
  .px-md-45 {
    padding-left: 2.8125rem !important;
    padding-right: 2.8125rem !important;
  }
  .py-md-45 {
    padding-bottom: 2.8125rem !important;
    padding-top: 2.8125rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-md-50 {
    padding: 3.125rem !important;
  }
  .pt-md-50 {
    padding-top: 3.125rem !important;
  }
  .pr-md-50 {
    padding-right: 3.125rem !important;
  }
  .pb-md-50 {
    padding-bottom: 3.125rem !important;
  }
  .pl-md-50 {
    padding-left: 3.125rem !important;
  }
  .px-md-50 {
    padding-left: 3.125rem !important;
    padding-right: 3.125rem !important;
  }
  .py-md-50 {
    padding-bottom: 3.125rem !important;
    padding-top: 3.125rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-md-55 {
    padding: 3.4375rem !important;
  }
  .pt-md-55 {
    padding-top: 3.4375rem !important;
  }
  .pr-md-55 {
    padding-right: 3.4375rem !important;
  }
  .pb-md-55 {
    padding-bottom: 3.4375rem !important;
  }
  .pl-md-55 {
    padding-left: 3.4375rem !important;
  }
  .px-md-55 {
    padding-left: 3.4375rem !important;
    padding-right: 3.4375rem !important;
  }
  .py-md-55 {
    padding-bottom: 3.4375rem !important;
    padding-top: 3.4375rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-md-60 {
    padding: 3.75rem !important;
  }
  .pt-md-60 {
    padding-top: 3.75rem !important;
  }
  .pr-md-60 {
    padding-right: 3.75rem !important;
  }
  .pb-md-60 {
    padding-bottom: 3.75rem !important;
  }
  .pl-md-60 {
    padding-left: 3.75rem !important;
  }
  .px-md-60 {
    padding-left: 3.75rem !important;
    padding-right: 3.75rem !important;
  }
  .py-md-60 {
    padding-bottom: 3.75rem !important;
    padding-top: 3.75rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-md-65 {
    padding: 4.0625rem !important;
  }
  .pt-md-65 {
    padding-top: 4.0625rem !important;
  }
  .pr-md-65 {
    padding-right: 4.0625rem !important;
  }
  .pb-md-65 {
    padding-bottom: 4.0625rem !important;
  }
  .pl-md-65 {
    padding-left: 4.0625rem !important;
  }
  .px-md-65 {
    padding-left: 4.0625rem !important;
    padding-right: 4.0625rem !important;
  }
  .py-md-65 {
    padding-bottom: 4.0625rem !important;
    padding-top: 4.0625rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-md-70 {
    padding: 4.375rem !important;
  }
  .pt-md-70 {
    padding-top: 4.375rem !important;
  }
  .pr-md-70 {
    padding-right: 4.375rem !important;
  }
  .pb-md-70 {
    padding-bottom: 4.375rem !important;
  }
  .pl-md-70 {
    padding-left: 4.375rem !important;
  }
  .px-md-70 {
    padding-left: 4.375rem !important;
    padding-right: 4.375rem !important;
  }
  .py-md-70 {
    padding-bottom: 4.375rem !important;
    padding-top: 4.375rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-md-75 {
    padding: 4.6875rem !important;
  }
  .pt-md-75 {
    padding-top: 4.6875rem !important;
  }
  .pr-md-75 {
    padding-right: 4.6875rem !important;
  }
  .pb-md-75 {
    padding-bottom: 4.6875rem !important;
  }
  .pl-md-75 {
    padding-left: 4.6875rem !important;
  }
  .px-md-75 {
    padding-left: 4.6875rem !important;
    padding-right: 4.6875rem !important;
  }
  .py-md-75 {
    padding-bottom: 4.6875rem !important;
    padding-top: 4.6875rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-md-80 {
    padding: 5rem !important;
  }
  .pt-md-80 {
    padding-top: 5rem !important;
  }
  .pr-md-80 {
    padding-right: 5rem !important;
  }
  .pb-md-80 {
    padding-bottom: 5rem !important;
  }
  .pl-md-80 {
    padding-left: 5rem !important;
  }
  .px-md-80 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .py-md-80 {
    padding-bottom: 5rem !important;
    padding-top: 5rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-md-85 {
    padding: 5.3125rem !important;
  }
  .pt-md-85 {
    padding-top: 5.3125rem !important;
  }
  .pr-md-85 {
    padding-right: 5.3125rem !important;
  }
  .pb-md-85 {
    padding-bottom: 5.3125rem !important;
  }
  .pl-md-85 {
    padding-left: 5.3125rem !important;
  }
  .px-md-85 {
    padding-left: 5.3125rem !important;
    padding-right: 5.3125rem !important;
  }
  .py-md-85 {
    padding-bottom: 5.3125rem !important;
    padding-top: 5.3125rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-md-90 {
    padding: 5.625rem !important;
  }
  .pt-md-90 {
    padding-top: 5.625rem !important;
  }
  .pr-md-90 {
    padding-right: 5.625rem !important;
  }
  .pb-md-90 {
    padding-bottom: 5.625rem !important;
  }
  .pl-md-90 {
    padding-left: 5.625rem !important;
  }
  .px-md-90 {
    padding-left: 5.625rem !important;
    padding-right: 5.625rem !important;
  }
  .py-md-90 {
    padding-bottom: 5.625rem !important;
    padding-top: 5.625rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-md-95 {
    padding: 5.9375rem !important;
  }
  .pt-md-95 {
    padding-top: 5.9375rem !important;
  }
  .pr-md-95 {
    padding-right: 5.9375rem !important;
  }
  .pb-md-95 {
    padding-bottom: 5.9375rem !important;
  }
  .pl-md-95 {
    padding-left: 5.9375rem !important;
  }
  .px-md-95 {
    padding-left: 5.9375rem !important;
    padding-right: 5.9375rem !important;
  }
  .py-md-95 {
    padding-bottom: 5.9375rem !important;
    padding-top: 5.9375rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-md-100 {
    padding: 6.25rem !important;
  }
  .pt-md-100 {
    padding-top: 6.25rem !important;
  }
  .pr-md-100 {
    padding-right: 6.25rem !important;
  }
  .pb-md-100 {
    padding-bottom: 6.25rem !important;
  }
  .pl-md-100 {
    padding-left: 6.25rem !important;
  }
  .px-md-100 {
    padding-left: 6.25rem !important;
    padding-right: 6.25rem !important;
  }
  .py-md-100 {
    padding-bottom: 6.25rem !important;
    padding-top: 6.25rem !important;
  }
  /* stylelint-enable */
}

@media (min-width: 992px) {
  /* stylelint-disable declaration-colon-space-after */
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0 {
    margin-left: 0 !important;
  }
  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .my-lg-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-lg-05 {
    margin: 0.3125rem !important;
  }
  .mt-lg-05 {
    margin-top: 0.3125rem !important;
  }
  .mr-lg-05 {
    margin-right: 0.3125rem !important;
  }
  .mb-lg-05 {
    margin-bottom: 0.3125rem !important;
  }
  .ml-lg-05 {
    margin-left: 0.3125rem !important;
  }
  .mx-lg-05 {
    margin-left: 0.3125rem !important;
    margin-right: 0.3125rem !important;
  }
  .my-lg-05 {
    margin-bottom: 0.3125rem !important;
    margin-top: 0.3125rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-lg-10 {
    margin: 0.625rem !important;
  }
  .mt-lg-10 {
    margin-top: 0.625rem !important;
  }
  .mr-lg-10 {
    margin-right: 0.625rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 0.625rem !important;
  }
  .ml-lg-10 {
    margin-left: 0.625rem !important;
  }
  .mx-lg-10 {
    margin-left: 0.625rem !important;
    margin-right: 0.625rem !important;
  }
  .my-lg-10 {
    margin-bottom: 0.625rem !important;
    margin-top: 0.625rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-lg-15 {
    margin: 0.9375rem !important;
  }
  .mt-lg-15 {
    margin-top: 0.9375rem !important;
  }
  .mr-lg-15 {
    margin-right: 0.9375rem !important;
  }
  .mb-lg-15 {
    margin-bottom: 0.9375rem !important;
  }
  .ml-lg-15 {
    margin-left: 0.9375rem !important;
  }
  .mx-lg-15 {
    margin-left: 0.9375rem !important;
    margin-right: 0.9375rem !important;
  }
  .my-lg-15 {
    margin-bottom: 0.9375rem !important;
    margin-top: 0.9375rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-lg-20 {
    margin: 1.25rem !important;
  }
  .mt-lg-20 {
    margin-top: 1.25rem !important;
  }
  .mr-lg-20 {
    margin-right: 1.25rem !important;
  }
  .mb-lg-20 {
    margin-bottom: 1.25rem !important;
  }
  .ml-lg-20 {
    margin-left: 1.25rem !important;
  }
  .mx-lg-20 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }
  .my-lg-20 {
    margin-bottom: 1.25rem !important;
    margin-top: 1.25rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-lg-25 {
    margin: 1.5625rem !important;
  }
  .mt-lg-25 {
    margin-top: 1.5625rem !important;
  }
  .mr-lg-25 {
    margin-right: 1.5625rem !important;
  }
  .mb-lg-25 {
    margin-bottom: 1.5625rem !important;
  }
  .ml-lg-25 {
    margin-left: 1.5625rem !important;
  }
  .mx-lg-25 {
    margin-left: 1.5625rem !important;
    margin-right: 1.5625rem !important;
  }
  .my-lg-25 {
    margin-bottom: 1.5625rem !important;
    margin-top: 1.5625rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-lg-30 {
    margin: 1.875rem !important;
  }
  .mt-lg-30 {
    margin-top: 1.875rem !important;
  }
  .mr-lg-30 {
    margin-right: 1.875rem !important;
  }
  .mb-lg-30 {
    margin-bottom: 1.875rem !important;
  }
  .ml-lg-30 {
    margin-left: 1.875rem !important;
  }
  .mx-lg-30 {
    margin-left: 1.875rem !important;
    margin-right: 1.875rem !important;
  }
  .my-lg-30 {
    margin-bottom: 1.875rem !important;
    margin-top: 1.875rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-lg-35 {
    margin: 2.1875rem !important;
  }
  .mt-lg-35 {
    margin-top: 2.1875rem !important;
  }
  .mr-lg-35 {
    margin-right: 2.1875rem !important;
  }
  .mb-lg-35 {
    margin-bottom: 2.1875rem !important;
  }
  .ml-lg-35 {
    margin-left: 2.1875rem !important;
  }
  .mx-lg-35 {
    margin-left: 2.1875rem !important;
    margin-right: 2.1875rem !important;
  }
  .my-lg-35 {
    margin-bottom: 2.1875rem !important;
    margin-top: 2.1875rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-lg-40 {
    margin: 2.5rem !important;
  }
  .mt-lg-40 {
    margin-top: 2.5rem !important;
  }
  .mr-lg-40 {
    margin-right: 2.5rem !important;
  }
  .mb-lg-40 {
    margin-bottom: 2.5rem !important;
  }
  .ml-lg-40 {
    margin-left: 2.5rem !important;
  }
  .mx-lg-40 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .my-lg-40 {
    margin-bottom: 2.5rem !important;
    margin-top: 2.5rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-lg-45 {
    margin: 2.8125rem !important;
  }
  .mt-lg-45 {
    margin-top: 2.8125rem !important;
  }
  .mr-lg-45 {
    margin-right: 2.8125rem !important;
  }
  .mb-lg-45 {
    margin-bottom: 2.8125rem !important;
  }
  .ml-lg-45 {
    margin-left: 2.8125rem !important;
  }
  .mx-lg-45 {
    margin-left: 2.8125rem !important;
    margin-right: 2.8125rem !important;
  }
  .my-lg-45 {
    margin-bottom: 2.8125rem !important;
    margin-top: 2.8125rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-lg-50 {
    margin: 3.125rem !important;
  }
  .mt-lg-50 {
    margin-top: 3.125rem !important;
  }
  .mr-lg-50 {
    margin-right: 3.125rem !important;
  }
  .mb-lg-50 {
    margin-bottom: 3.125rem !important;
  }
  .ml-lg-50 {
    margin-left: 3.125rem !important;
  }
  .mx-lg-50 {
    margin-left: 3.125rem !important;
    margin-right: 3.125rem !important;
  }
  .my-lg-50 {
    margin-bottom: 3.125rem !important;
    margin-top: 3.125rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-lg-55 {
    margin: 3.4375rem !important;
  }
  .mt-lg-55 {
    margin-top: 3.4375rem !important;
  }
  .mr-lg-55 {
    margin-right: 3.4375rem !important;
  }
  .mb-lg-55 {
    margin-bottom: 3.4375rem !important;
  }
  .ml-lg-55 {
    margin-left: 3.4375rem !important;
  }
  .mx-lg-55 {
    margin-left: 3.4375rem !important;
    margin-right: 3.4375rem !important;
  }
  .my-lg-55 {
    margin-bottom: 3.4375rem !important;
    margin-top: 3.4375rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-lg-60 {
    margin: 3.75rem !important;
  }
  .mt-lg-60 {
    margin-top: 3.75rem !important;
  }
  .mr-lg-60 {
    margin-right: 3.75rem !important;
  }
  .mb-lg-60 {
    margin-bottom: 3.75rem !important;
  }
  .ml-lg-60 {
    margin-left: 3.75rem !important;
  }
  .mx-lg-60 {
    margin-left: 3.75rem !important;
    margin-right: 3.75rem !important;
  }
  .my-lg-60 {
    margin-bottom: 3.75rem !important;
    margin-top: 3.75rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-lg-65 {
    margin: 4.0625rem !important;
  }
  .mt-lg-65 {
    margin-top: 4.0625rem !important;
  }
  .mr-lg-65 {
    margin-right: 4.0625rem !important;
  }
  .mb-lg-65 {
    margin-bottom: 4.0625rem !important;
  }
  .ml-lg-65 {
    margin-left: 4.0625rem !important;
  }
  .mx-lg-65 {
    margin-left: 4.0625rem !important;
    margin-right: 4.0625rem !important;
  }
  .my-lg-65 {
    margin-bottom: 4.0625rem !important;
    margin-top: 4.0625rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-lg-70 {
    margin: 4.375rem !important;
  }
  .mt-lg-70 {
    margin-top: 4.375rem !important;
  }
  .mr-lg-70 {
    margin-right: 4.375rem !important;
  }
  .mb-lg-70 {
    margin-bottom: 4.375rem !important;
  }
  .ml-lg-70 {
    margin-left: 4.375rem !important;
  }
  .mx-lg-70 {
    margin-left: 4.375rem !important;
    margin-right: 4.375rem !important;
  }
  .my-lg-70 {
    margin-bottom: 4.375rem !important;
    margin-top: 4.375rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-lg-75 {
    margin: 4.6875rem !important;
  }
  .mt-lg-75 {
    margin-top: 4.6875rem !important;
  }
  .mr-lg-75 {
    margin-right: 4.6875rem !important;
  }
  .mb-lg-75 {
    margin-bottom: 4.6875rem !important;
  }
  .ml-lg-75 {
    margin-left: 4.6875rem !important;
  }
  .mx-lg-75 {
    margin-left: 4.6875rem !important;
    margin-right: 4.6875rem !important;
  }
  .my-lg-75 {
    margin-bottom: 4.6875rem !important;
    margin-top: 4.6875rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-lg-80 {
    margin: 5rem !important;
  }
  .mt-lg-80 {
    margin-top: 5rem !important;
  }
  .mr-lg-80 {
    margin-right: 5rem !important;
  }
  .mb-lg-80 {
    margin-bottom: 5rem !important;
  }
  .ml-lg-80 {
    margin-left: 5rem !important;
  }
  .mx-lg-80 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .my-lg-80 {
    margin-bottom: 5rem !important;
    margin-top: 5rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-lg-85 {
    margin: 5.3125rem !important;
  }
  .mt-lg-85 {
    margin-top: 5.3125rem !important;
  }
  .mr-lg-85 {
    margin-right: 5.3125rem !important;
  }
  .mb-lg-85 {
    margin-bottom: 5.3125rem !important;
  }
  .ml-lg-85 {
    margin-left: 5.3125rem !important;
  }
  .mx-lg-85 {
    margin-left: 5.3125rem !important;
    margin-right: 5.3125rem !important;
  }
  .my-lg-85 {
    margin-bottom: 5.3125rem !important;
    margin-top: 5.3125rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-lg-90 {
    margin: 5.625rem !important;
  }
  .mt-lg-90 {
    margin-top: 5.625rem !important;
  }
  .mr-lg-90 {
    margin-right: 5.625rem !important;
  }
  .mb-lg-90 {
    margin-bottom: 5.625rem !important;
  }
  .ml-lg-90 {
    margin-left: 5.625rem !important;
  }
  .mx-lg-90 {
    margin-left: 5.625rem !important;
    margin-right: 5.625rem !important;
  }
  .my-lg-90 {
    margin-bottom: 5.625rem !important;
    margin-top: 5.625rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-lg-95 {
    margin: 5.9375rem !important;
  }
  .mt-lg-95 {
    margin-top: 5.9375rem !important;
  }
  .mr-lg-95 {
    margin-right: 5.9375rem !important;
  }
  .mb-lg-95 {
    margin-bottom: 5.9375rem !important;
  }
  .ml-lg-95 {
    margin-left: 5.9375rem !important;
  }
  .mx-lg-95 {
    margin-left: 5.9375rem !important;
    margin-right: 5.9375rem !important;
  }
  .my-lg-95 {
    margin-bottom: 5.9375rem !important;
    margin-top: 5.9375rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-lg-100 {
    margin: 6.25rem !important;
  }
  .mt-lg-100 {
    margin-top: 6.25rem !important;
  }
  .mr-lg-100 {
    margin-right: 6.25rem !important;
  }
  .mb-lg-100 {
    margin-bottom: 6.25rem !important;
  }
  .ml-lg-100 {
    margin-left: 6.25rem !important;
  }
  .mx-lg-100 {
    margin-left: 6.25rem !important;
    margin-right: 6.25rem !important;
  }
  .my-lg-100 {
    margin-bottom: 6.25rem !important;
    margin-top: 6.25rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0 {
    padding-left: 0 !important;
  }
  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .py-lg-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-lg-05 {
    padding: 0.3125rem !important;
  }
  .pt-lg-05 {
    padding-top: 0.3125rem !important;
  }
  .pr-lg-05 {
    padding-right: 0.3125rem !important;
  }
  .pb-lg-05 {
    padding-bottom: 0.3125rem !important;
  }
  .pl-lg-05 {
    padding-left: 0.3125rem !important;
  }
  .px-lg-05 {
    padding-left: 0.3125rem !important;
    padding-right: 0.3125rem !important;
  }
  .py-lg-05 {
    padding-bottom: 0.3125rem !important;
    padding-top: 0.3125rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-lg-10 {
    padding: 0.625rem !important;
  }
  .pt-lg-10 {
    padding-top: 0.625rem !important;
  }
  .pr-lg-10 {
    padding-right: 0.625rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 0.625rem !important;
  }
  .pl-lg-10 {
    padding-left: 0.625rem !important;
  }
  .px-lg-10 {
    padding-left: 0.625rem !important;
    padding-right: 0.625rem !important;
  }
  .py-lg-10 {
    padding-bottom: 0.625rem !important;
    padding-top: 0.625rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-lg-15 {
    padding: 0.9375rem !important;
  }
  .pt-lg-15 {
    padding-top: 0.9375rem !important;
  }
  .pr-lg-15 {
    padding-right: 0.9375rem !important;
  }
  .pb-lg-15 {
    padding-bottom: 0.9375rem !important;
  }
  .pl-lg-15 {
    padding-left: 0.9375rem !important;
  }
  .px-lg-15 {
    padding-left: 0.9375rem !important;
    padding-right: 0.9375rem !important;
  }
  .py-lg-15 {
    padding-bottom: 0.9375rem !important;
    padding-top: 0.9375rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-lg-20 {
    padding: 1.25rem !important;
  }
  .pt-lg-20 {
    padding-top: 1.25rem !important;
  }
  .pr-lg-20 {
    padding-right: 1.25rem !important;
  }
  .pb-lg-20 {
    padding-bottom: 1.25rem !important;
  }
  .pl-lg-20 {
    padding-left: 1.25rem !important;
  }
  .px-lg-20 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  .py-lg-20 {
    padding-bottom: 1.25rem !important;
    padding-top: 1.25rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-lg-25 {
    padding: 1.5625rem !important;
  }
  .pt-lg-25 {
    padding-top: 1.5625rem !important;
  }
  .pr-lg-25 {
    padding-right: 1.5625rem !important;
  }
  .pb-lg-25 {
    padding-bottom: 1.5625rem !important;
  }
  .pl-lg-25 {
    padding-left: 1.5625rem !important;
  }
  .px-lg-25 {
    padding-left: 1.5625rem !important;
    padding-right: 1.5625rem !important;
  }
  .py-lg-25 {
    padding-bottom: 1.5625rem !important;
    padding-top: 1.5625rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-lg-30 {
    padding: 1.875rem !important;
  }
  .pt-lg-30 {
    padding-top: 1.875rem !important;
  }
  .pr-lg-30 {
    padding-right: 1.875rem !important;
  }
  .pb-lg-30 {
    padding-bottom: 1.875rem !important;
  }
  .pl-lg-30 {
    padding-left: 1.875rem !important;
  }
  .px-lg-30 {
    padding-left: 1.875rem !important;
    padding-right: 1.875rem !important;
  }
  .py-lg-30 {
    padding-bottom: 1.875rem !important;
    padding-top: 1.875rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-lg-35 {
    padding: 2.1875rem !important;
  }
  .pt-lg-35 {
    padding-top: 2.1875rem !important;
  }
  .pr-lg-35 {
    padding-right: 2.1875rem !important;
  }
  .pb-lg-35 {
    padding-bottom: 2.1875rem !important;
  }
  .pl-lg-35 {
    padding-left: 2.1875rem !important;
  }
  .px-lg-35 {
    padding-left: 2.1875rem !important;
    padding-right: 2.1875rem !important;
  }
  .py-lg-35 {
    padding-bottom: 2.1875rem !important;
    padding-top: 2.1875rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-lg-40 {
    padding: 2.5rem !important;
  }
  .pt-lg-40 {
    padding-top: 2.5rem !important;
  }
  .pr-lg-40 {
    padding-right: 2.5rem !important;
  }
  .pb-lg-40 {
    padding-bottom: 2.5rem !important;
  }
  .pl-lg-40 {
    padding-left: 2.5rem !important;
  }
  .px-lg-40 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .py-lg-40 {
    padding-bottom: 2.5rem !important;
    padding-top: 2.5rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-lg-45 {
    padding: 2.8125rem !important;
  }
  .pt-lg-45 {
    padding-top: 2.8125rem !important;
  }
  .pr-lg-45 {
    padding-right: 2.8125rem !important;
  }
  .pb-lg-45 {
    padding-bottom: 2.8125rem !important;
  }
  .pl-lg-45 {
    padding-left: 2.8125rem !important;
  }
  .px-lg-45 {
    padding-left: 2.8125rem !important;
    padding-right: 2.8125rem !important;
  }
  .py-lg-45 {
    padding-bottom: 2.8125rem !important;
    padding-top: 2.8125rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-lg-50 {
    padding: 3.125rem !important;
  }
  .pt-lg-50 {
    padding-top: 3.125rem !important;
  }
  .pr-lg-50 {
    padding-right: 3.125rem !important;
  }
  .pb-lg-50 {
    padding-bottom: 3.125rem !important;
  }
  .pl-lg-50 {
    padding-left: 3.125rem !important;
  }
  .px-lg-50 {
    padding-left: 3.125rem !important;
    padding-right: 3.125rem !important;
  }
  .py-lg-50 {
    padding-bottom: 3.125rem !important;
    padding-top: 3.125rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-lg-55 {
    padding: 3.4375rem !important;
  }
  .pt-lg-55 {
    padding-top: 3.4375rem !important;
  }
  .pr-lg-55 {
    padding-right: 3.4375rem !important;
  }
  .pb-lg-55 {
    padding-bottom: 3.4375rem !important;
  }
  .pl-lg-55 {
    padding-left: 3.4375rem !important;
  }
  .px-lg-55 {
    padding-left: 3.4375rem !important;
    padding-right: 3.4375rem !important;
  }
  .py-lg-55 {
    padding-bottom: 3.4375rem !important;
    padding-top: 3.4375rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-lg-60 {
    padding: 3.75rem !important;
  }
  .pt-lg-60 {
    padding-top: 3.75rem !important;
  }
  .pr-lg-60 {
    padding-right: 3.75rem !important;
  }
  .pb-lg-60 {
    padding-bottom: 3.75rem !important;
  }
  .pl-lg-60 {
    padding-left: 3.75rem !important;
  }
  .px-lg-60 {
    padding-left: 3.75rem !important;
    padding-right: 3.75rem !important;
  }
  .py-lg-60 {
    padding-bottom: 3.75rem !important;
    padding-top: 3.75rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-lg-65 {
    padding: 4.0625rem !important;
  }
  .pt-lg-65 {
    padding-top: 4.0625rem !important;
  }
  .pr-lg-65 {
    padding-right: 4.0625rem !important;
  }
  .pb-lg-65 {
    padding-bottom: 4.0625rem !important;
  }
  .pl-lg-65 {
    padding-left: 4.0625rem !important;
  }
  .px-lg-65 {
    padding-left: 4.0625rem !important;
    padding-right: 4.0625rem !important;
  }
  .py-lg-65 {
    padding-bottom: 4.0625rem !important;
    padding-top: 4.0625rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-lg-70 {
    padding: 4.375rem !important;
  }
  .pt-lg-70 {
    padding-top: 4.375rem !important;
  }
  .pr-lg-70 {
    padding-right: 4.375rem !important;
  }
  .pb-lg-70 {
    padding-bottom: 4.375rem !important;
  }
  .pl-lg-70 {
    padding-left: 4.375rem !important;
  }
  .px-lg-70 {
    padding-left: 4.375rem !important;
    padding-right: 4.375rem !important;
  }
  .py-lg-70 {
    padding-bottom: 4.375rem !important;
    padding-top: 4.375rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-lg-75 {
    padding: 4.6875rem !important;
  }
  .pt-lg-75 {
    padding-top: 4.6875rem !important;
  }
  .pr-lg-75 {
    padding-right: 4.6875rem !important;
  }
  .pb-lg-75 {
    padding-bottom: 4.6875rem !important;
  }
  .pl-lg-75 {
    padding-left: 4.6875rem !important;
  }
  .px-lg-75 {
    padding-left: 4.6875rem !important;
    padding-right: 4.6875rem !important;
  }
  .py-lg-75 {
    padding-bottom: 4.6875rem !important;
    padding-top: 4.6875rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-lg-80 {
    padding: 5rem !important;
  }
  .pt-lg-80 {
    padding-top: 5rem !important;
  }
  .pr-lg-80 {
    padding-right: 5rem !important;
  }
  .pb-lg-80 {
    padding-bottom: 5rem !important;
  }
  .pl-lg-80 {
    padding-left: 5rem !important;
  }
  .px-lg-80 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .py-lg-80 {
    padding-bottom: 5rem !important;
    padding-top: 5rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-lg-85 {
    padding: 5.3125rem !important;
  }
  .pt-lg-85 {
    padding-top: 5.3125rem !important;
  }
  .pr-lg-85 {
    padding-right: 5.3125rem !important;
  }
  .pb-lg-85 {
    padding-bottom: 5.3125rem !important;
  }
  .pl-lg-85 {
    padding-left: 5.3125rem !important;
  }
  .px-lg-85 {
    padding-left: 5.3125rem !important;
    padding-right: 5.3125rem !important;
  }
  .py-lg-85 {
    padding-bottom: 5.3125rem !important;
    padding-top: 5.3125rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-lg-90 {
    padding: 5.625rem !important;
  }
  .pt-lg-90 {
    padding-top: 5.625rem !important;
  }
  .pr-lg-90 {
    padding-right: 5.625rem !important;
  }
  .pb-lg-90 {
    padding-bottom: 5.625rem !important;
  }
  .pl-lg-90 {
    padding-left: 5.625rem !important;
  }
  .px-lg-90 {
    padding-left: 5.625rem !important;
    padding-right: 5.625rem !important;
  }
  .py-lg-90 {
    padding-bottom: 5.625rem !important;
    padding-top: 5.625rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-lg-95 {
    padding: 5.9375rem !important;
  }
  .pt-lg-95 {
    padding-top: 5.9375rem !important;
  }
  .pr-lg-95 {
    padding-right: 5.9375rem !important;
  }
  .pb-lg-95 {
    padding-bottom: 5.9375rem !important;
  }
  .pl-lg-95 {
    padding-left: 5.9375rem !important;
  }
  .px-lg-95 {
    padding-left: 5.9375rem !important;
    padding-right: 5.9375rem !important;
  }
  .py-lg-95 {
    padding-bottom: 5.9375rem !important;
    padding-top: 5.9375rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-lg-100 {
    padding: 6.25rem !important;
  }
  .pt-lg-100 {
    padding-top: 6.25rem !important;
  }
  .pr-lg-100 {
    padding-right: 6.25rem !important;
  }
  .pb-lg-100 {
    padding-bottom: 6.25rem !important;
  }
  .pl-lg-100 {
    padding-left: 6.25rem !important;
  }
  .px-lg-100 {
    padding-left: 6.25rem !important;
    padding-right: 6.25rem !important;
  }
  .py-lg-100 {
    padding-bottom: 6.25rem !important;
    padding-top: 6.25rem !important;
  }
  /* stylelint-enable */
}

@media (min-width: 1200px) {
  /* stylelint-disable declaration-colon-space-after */
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0 {
    margin-left: 0 !important;
  }
  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .my-xl-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-xl-05 {
    margin: 0.3125rem !important;
  }
  .mt-xl-05 {
    margin-top: 0.3125rem !important;
  }
  .mr-xl-05 {
    margin-right: 0.3125rem !important;
  }
  .mb-xl-05 {
    margin-bottom: 0.3125rem !important;
  }
  .ml-xl-05 {
    margin-left: 0.3125rem !important;
  }
  .mx-xl-05 {
    margin-left: 0.3125rem !important;
    margin-right: 0.3125rem !important;
  }
  .my-xl-05 {
    margin-bottom: 0.3125rem !important;
    margin-top: 0.3125rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-xl-10 {
    margin: 0.625rem !important;
  }
  .mt-xl-10 {
    margin-top: 0.625rem !important;
  }
  .mr-xl-10 {
    margin-right: 0.625rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 0.625rem !important;
  }
  .ml-xl-10 {
    margin-left: 0.625rem !important;
  }
  .mx-xl-10 {
    margin-left: 0.625rem !important;
    margin-right: 0.625rem !important;
  }
  .my-xl-10 {
    margin-bottom: 0.625rem !important;
    margin-top: 0.625rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-xl-15 {
    margin: 0.9375rem !important;
  }
  .mt-xl-15 {
    margin-top: 0.9375rem !important;
  }
  .mr-xl-15 {
    margin-right: 0.9375rem !important;
  }
  .mb-xl-15 {
    margin-bottom: 0.9375rem !important;
  }
  .ml-xl-15 {
    margin-left: 0.9375rem !important;
  }
  .mx-xl-15 {
    margin-left: 0.9375rem !important;
    margin-right: 0.9375rem !important;
  }
  .my-xl-15 {
    margin-bottom: 0.9375rem !important;
    margin-top: 0.9375rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-xl-20 {
    margin: 1.25rem !important;
  }
  .mt-xl-20 {
    margin-top: 1.25rem !important;
  }
  .mr-xl-20 {
    margin-right: 1.25rem !important;
  }
  .mb-xl-20 {
    margin-bottom: 1.25rem !important;
  }
  .ml-xl-20 {
    margin-left: 1.25rem !important;
  }
  .mx-xl-20 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }
  .my-xl-20 {
    margin-bottom: 1.25rem !important;
    margin-top: 1.25rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-xl-25 {
    margin: 1.5625rem !important;
  }
  .mt-xl-25 {
    margin-top: 1.5625rem !important;
  }
  .mr-xl-25 {
    margin-right: 1.5625rem !important;
  }
  .mb-xl-25 {
    margin-bottom: 1.5625rem !important;
  }
  .ml-xl-25 {
    margin-left: 1.5625rem !important;
  }
  .mx-xl-25 {
    margin-left: 1.5625rem !important;
    margin-right: 1.5625rem !important;
  }
  .my-xl-25 {
    margin-bottom: 1.5625rem !important;
    margin-top: 1.5625rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-xl-30 {
    margin: 1.875rem !important;
  }
  .mt-xl-30 {
    margin-top: 1.875rem !important;
  }
  .mr-xl-30 {
    margin-right: 1.875rem !important;
  }
  .mb-xl-30 {
    margin-bottom: 1.875rem !important;
  }
  .ml-xl-30 {
    margin-left: 1.875rem !important;
  }
  .mx-xl-30 {
    margin-left: 1.875rem !important;
    margin-right: 1.875rem !important;
  }
  .my-xl-30 {
    margin-bottom: 1.875rem !important;
    margin-top: 1.875rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-xl-35 {
    margin: 2.1875rem !important;
  }
  .mt-xl-35 {
    margin-top: 2.1875rem !important;
  }
  .mr-xl-35 {
    margin-right: 2.1875rem !important;
  }
  .mb-xl-35 {
    margin-bottom: 2.1875rem !important;
  }
  .ml-xl-35 {
    margin-left: 2.1875rem !important;
  }
  .mx-xl-35 {
    margin-left: 2.1875rem !important;
    margin-right: 2.1875rem !important;
  }
  .my-xl-35 {
    margin-bottom: 2.1875rem !important;
    margin-top: 2.1875rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-xl-40 {
    margin: 2.5rem !important;
  }
  .mt-xl-40 {
    margin-top: 2.5rem !important;
  }
  .mr-xl-40 {
    margin-right: 2.5rem !important;
  }
  .mb-xl-40 {
    margin-bottom: 2.5rem !important;
  }
  .ml-xl-40 {
    margin-left: 2.5rem !important;
  }
  .mx-xl-40 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .my-xl-40 {
    margin-bottom: 2.5rem !important;
    margin-top: 2.5rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-xl-45 {
    margin: 2.8125rem !important;
  }
  .mt-xl-45 {
    margin-top: 2.8125rem !important;
  }
  .mr-xl-45 {
    margin-right: 2.8125rem !important;
  }
  .mb-xl-45 {
    margin-bottom: 2.8125rem !important;
  }
  .ml-xl-45 {
    margin-left: 2.8125rem !important;
  }
  .mx-xl-45 {
    margin-left: 2.8125rem !important;
    margin-right: 2.8125rem !important;
  }
  .my-xl-45 {
    margin-bottom: 2.8125rem !important;
    margin-top: 2.8125rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-xl-50 {
    margin: 3.125rem !important;
  }
  .mt-xl-50 {
    margin-top: 3.125rem !important;
  }
  .mr-xl-50 {
    margin-right: 3.125rem !important;
  }
  .mb-xl-50 {
    margin-bottom: 3.125rem !important;
  }
  .ml-xl-50 {
    margin-left: 3.125rem !important;
  }
  .mx-xl-50 {
    margin-left: 3.125rem !important;
    margin-right: 3.125rem !important;
  }
  .my-xl-50 {
    margin-bottom: 3.125rem !important;
    margin-top: 3.125rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-xl-55 {
    margin: 3.4375rem !important;
  }
  .mt-xl-55 {
    margin-top: 3.4375rem !important;
  }
  .mr-xl-55 {
    margin-right: 3.4375rem !important;
  }
  .mb-xl-55 {
    margin-bottom: 3.4375rem !important;
  }
  .ml-xl-55 {
    margin-left: 3.4375rem !important;
  }
  .mx-xl-55 {
    margin-left: 3.4375rem !important;
    margin-right: 3.4375rem !important;
  }
  .my-xl-55 {
    margin-bottom: 3.4375rem !important;
    margin-top: 3.4375rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-xl-60 {
    margin: 3.75rem !important;
  }
  .mt-xl-60 {
    margin-top: 3.75rem !important;
  }
  .mr-xl-60 {
    margin-right: 3.75rem !important;
  }
  .mb-xl-60 {
    margin-bottom: 3.75rem !important;
  }
  .ml-xl-60 {
    margin-left: 3.75rem !important;
  }
  .mx-xl-60 {
    margin-left: 3.75rem !important;
    margin-right: 3.75rem !important;
  }
  .my-xl-60 {
    margin-bottom: 3.75rem !important;
    margin-top: 3.75rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-xl-65 {
    margin: 4.0625rem !important;
  }
  .mt-xl-65 {
    margin-top: 4.0625rem !important;
  }
  .mr-xl-65 {
    margin-right: 4.0625rem !important;
  }
  .mb-xl-65 {
    margin-bottom: 4.0625rem !important;
  }
  .ml-xl-65 {
    margin-left: 4.0625rem !important;
  }
  .mx-xl-65 {
    margin-left: 4.0625rem !important;
    margin-right: 4.0625rem !important;
  }
  .my-xl-65 {
    margin-bottom: 4.0625rem !important;
    margin-top: 4.0625rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-xl-70 {
    margin: 4.375rem !important;
  }
  .mt-xl-70 {
    margin-top: 4.375rem !important;
  }
  .mr-xl-70 {
    margin-right: 4.375rem !important;
  }
  .mb-xl-70 {
    margin-bottom: 4.375rem !important;
  }
  .ml-xl-70 {
    margin-left: 4.375rem !important;
  }
  .mx-xl-70 {
    margin-left: 4.375rem !important;
    margin-right: 4.375rem !important;
  }
  .my-xl-70 {
    margin-bottom: 4.375rem !important;
    margin-top: 4.375rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-xl-75 {
    margin: 4.6875rem !important;
  }
  .mt-xl-75 {
    margin-top: 4.6875rem !important;
  }
  .mr-xl-75 {
    margin-right: 4.6875rem !important;
  }
  .mb-xl-75 {
    margin-bottom: 4.6875rem !important;
  }
  .ml-xl-75 {
    margin-left: 4.6875rem !important;
  }
  .mx-xl-75 {
    margin-left: 4.6875rem !important;
    margin-right: 4.6875rem !important;
  }
  .my-xl-75 {
    margin-bottom: 4.6875rem !important;
    margin-top: 4.6875rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-xl-80 {
    margin: 5rem !important;
  }
  .mt-xl-80 {
    margin-top: 5rem !important;
  }
  .mr-xl-80 {
    margin-right: 5rem !important;
  }
  .mb-xl-80 {
    margin-bottom: 5rem !important;
  }
  .ml-xl-80 {
    margin-left: 5rem !important;
  }
  .mx-xl-80 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .my-xl-80 {
    margin-bottom: 5rem !important;
    margin-top: 5rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-xl-85 {
    margin: 5.3125rem !important;
  }
  .mt-xl-85 {
    margin-top: 5.3125rem !important;
  }
  .mr-xl-85 {
    margin-right: 5.3125rem !important;
  }
  .mb-xl-85 {
    margin-bottom: 5.3125rem !important;
  }
  .ml-xl-85 {
    margin-left: 5.3125rem !important;
  }
  .mx-xl-85 {
    margin-left: 5.3125rem !important;
    margin-right: 5.3125rem !important;
  }
  .my-xl-85 {
    margin-bottom: 5.3125rem !important;
    margin-top: 5.3125rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-xl-90 {
    margin: 5.625rem !important;
  }
  .mt-xl-90 {
    margin-top: 5.625rem !important;
  }
  .mr-xl-90 {
    margin-right: 5.625rem !important;
  }
  .mb-xl-90 {
    margin-bottom: 5.625rem !important;
  }
  .ml-xl-90 {
    margin-left: 5.625rem !important;
  }
  .mx-xl-90 {
    margin-left: 5.625rem !important;
    margin-right: 5.625rem !important;
  }
  .my-xl-90 {
    margin-bottom: 5.625rem !important;
    margin-top: 5.625rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-xl-95 {
    margin: 5.9375rem !important;
  }
  .mt-xl-95 {
    margin-top: 5.9375rem !important;
  }
  .mr-xl-95 {
    margin-right: 5.9375rem !important;
  }
  .mb-xl-95 {
    margin-bottom: 5.9375rem !important;
  }
  .ml-xl-95 {
    margin-left: 5.9375rem !important;
  }
  .mx-xl-95 {
    margin-left: 5.9375rem !important;
    margin-right: 5.9375rem !important;
  }
  .my-xl-95 {
    margin-bottom: 5.9375rem !important;
    margin-top: 5.9375rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .m-xl-100 {
    margin: 6.25rem !important;
  }
  .mt-xl-100 {
    margin-top: 6.25rem !important;
  }
  .mr-xl-100 {
    margin-right: 6.25rem !important;
  }
  .mb-xl-100 {
    margin-bottom: 6.25rem !important;
  }
  .ml-xl-100 {
    margin-left: 6.25rem !important;
  }
  .mx-xl-100 {
    margin-left: 6.25rem !important;
    margin-right: 6.25rem !important;
  }
  .my-xl-100 {
    margin-bottom: 6.25rem !important;
    margin-top: 6.25rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0 {
    padding-left: 0 !important;
  }
  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .py-xl-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-xl-05 {
    padding: 0.3125rem !important;
  }
  .pt-xl-05 {
    padding-top: 0.3125rem !important;
  }
  .pr-xl-05 {
    padding-right: 0.3125rem !important;
  }
  .pb-xl-05 {
    padding-bottom: 0.3125rem !important;
  }
  .pl-xl-05 {
    padding-left: 0.3125rem !important;
  }
  .px-xl-05 {
    padding-left: 0.3125rem !important;
    padding-right: 0.3125rem !important;
  }
  .py-xl-05 {
    padding-bottom: 0.3125rem !important;
    padding-top: 0.3125rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-xl-10 {
    padding: 0.625rem !important;
  }
  .pt-xl-10 {
    padding-top: 0.625rem !important;
  }
  .pr-xl-10 {
    padding-right: 0.625rem !important;
  }
  .pb-xl-10 {
    padding-bottom: 0.625rem !important;
  }
  .pl-xl-10 {
    padding-left: 0.625rem !important;
  }
  .px-xl-10 {
    padding-left: 0.625rem !important;
    padding-right: 0.625rem !important;
  }
  .py-xl-10 {
    padding-bottom: 0.625rem !important;
    padding-top: 0.625rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-xl-15 {
    padding: 0.9375rem !important;
  }
  .pt-xl-15 {
    padding-top: 0.9375rem !important;
  }
  .pr-xl-15 {
    padding-right: 0.9375rem !important;
  }
  .pb-xl-15 {
    padding-bottom: 0.9375rem !important;
  }
  .pl-xl-15 {
    padding-left: 0.9375rem !important;
  }
  .px-xl-15 {
    padding-left: 0.9375rem !important;
    padding-right: 0.9375rem !important;
  }
  .py-xl-15 {
    padding-bottom: 0.9375rem !important;
    padding-top: 0.9375rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-xl-20 {
    padding: 1.25rem !important;
  }
  .pt-xl-20 {
    padding-top: 1.25rem !important;
  }
  .pr-xl-20 {
    padding-right: 1.25rem !important;
  }
  .pb-xl-20 {
    padding-bottom: 1.25rem !important;
  }
  .pl-xl-20 {
    padding-left: 1.25rem !important;
  }
  .px-xl-20 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  .py-xl-20 {
    padding-bottom: 1.25rem !important;
    padding-top: 1.25rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-xl-25 {
    padding: 1.5625rem !important;
  }
  .pt-xl-25 {
    padding-top: 1.5625rem !important;
  }
  .pr-xl-25 {
    padding-right: 1.5625rem !important;
  }
  .pb-xl-25 {
    padding-bottom: 1.5625rem !important;
  }
  .pl-xl-25 {
    padding-left: 1.5625rem !important;
  }
  .px-xl-25 {
    padding-left: 1.5625rem !important;
    padding-right: 1.5625rem !important;
  }
  .py-xl-25 {
    padding-bottom: 1.5625rem !important;
    padding-top: 1.5625rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-xl-30 {
    padding: 1.875rem !important;
  }
  .pt-xl-30 {
    padding-top: 1.875rem !important;
  }
  .pr-xl-30 {
    padding-right: 1.875rem !important;
  }
  .pb-xl-30 {
    padding-bottom: 1.875rem !important;
  }
  .pl-xl-30 {
    padding-left: 1.875rem !important;
  }
  .px-xl-30 {
    padding-left: 1.875rem !important;
    padding-right: 1.875rem !important;
  }
  .py-xl-30 {
    padding-bottom: 1.875rem !important;
    padding-top: 1.875rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-xl-35 {
    padding: 2.1875rem !important;
  }
  .pt-xl-35 {
    padding-top: 2.1875rem !important;
  }
  .pr-xl-35 {
    padding-right: 2.1875rem !important;
  }
  .pb-xl-35 {
    padding-bottom: 2.1875rem !important;
  }
  .pl-xl-35 {
    padding-left: 2.1875rem !important;
  }
  .px-xl-35 {
    padding-left: 2.1875rem !important;
    padding-right: 2.1875rem !important;
  }
  .py-xl-35 {
    padding-bottom: 2.1875rem !important;
    padding-top: 2.1875rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-xl-40 {
    padding: 2.5rem !important;
  }
  .pt-xl-40 {
    padding-top: 2.5rem !important;
  }
  .pr-xl-40 {
    padding-right: 2.5rem !important;
  }
  .pb-xl-40 {
    padding-bottom: 2.5rem !important;
  }
  .pl-xl-40 {
    padding-left: 2.5rem !important;
  }
  .px-xl-40 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .py-xl-40 {
    padding-bottom: 2.5rem !important;
    padding-top: 2.5rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-xl-45 {
    padding: 2.8125rem !important;
  }
  .pt-xl-45 {
    padding-top: 2.8125rem !important;
  }
  .pr-xl-45 {
    padding-right: 2.8125rem !important;
  }
  .pb-xl-45 {
    padding-bottom: 2.8125rem !important;
  }
  .pl-xl-45 {
    padding-left: 2.8125rem !important;
  }
  .px-xl-45 {
    padding-left: 2.8125rem !important;
    padding-right: 2.8125rem !important;
  }
  .py-xl-45 {
    padding-bottom: 2.8125rem !important;
    padding-top: 2.8125rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-xl-50 {
    padding: 3.125rem !important;
  }
  .pt-xl-50 {
    padding-top: 3.125rem !important;
  }
  .pr-xl-50 {
    padding-right: 3.125rem !important;
  }
  .pb-xl-50 {
    padding-bottom: 3.125rem !important;
  }
  .pl-xl-50 {
    padding-left: 3.125rem !important;
  }
  .px-xl-50 {
    padding-left: 3.125rem !important;
    padding-right: 3.125rem !important;
  }
  .py-xl-50 {
    padding-bottom: 3.125rem !important;
    padding-top: 3.125rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-xl-55 {
    padding: 3.4375rem !important;
  }
  .pt-xl-55 {
    padding-top: 3.4375rem !important;
  }
  .pr-xl-55 {
    padding-right: 3.4375rem !important;
  }
  .pb-xl-55 {
    padding-bottom: 3.4375rem !important;
  }
  .pl-xl-55 {
    padding-left: 3.4375rem !important;
  }
  .px-xl-55 {
    padding-left: 3.4375rem !important;
    padding-right: 3.4375rem !important;
  }
  .py-xl-55 {
    padding-bottom: 3.4375rem !important;
    padding-top: 3.4375rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-xl-60 {
    padding: 3.75rem !important;
  }
  .pt-xl-60 {
    padding-top: 3.75rem !important;
  }
  .pr-xl-60 {
    padding-right: 3.75rem !important;
  }
  .pb-xl-60 {
    padding-bottom: 3.75rem !important;
  }
  .pl-xl-60 {
    padding-left: 3.75rem !important;
  }
  .px-xl-60 {
    padding-left: 3.75rem !important;
    padding-right: 3.75rem !important;
  }
  .py-xl-60 {
    padding-bottom: 3.75rem !important;
    padding-top: 3.75rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-xl-65 {
    padding: 4.0625rem !important;
  }
  .pt-xl-65 {
    padding-top: 4.0625rem !important;
  }
  .pr-xl-65 {
    padding-right: 4.0625rem !important;
  }
  .pb-xl-65 {
    padding-bottom: 4.0625rem !important;
  }
  .pl-xl-65 {
    padding-left: 4.0625rem !important;
  }
  .px-xl-65 {
    padding-left: 4.0625rem !important;
    padding-right: 4.0625rem !important;
  }
  .py-xl-65 {
    padding-bottom: 4.0625rem !important;
    padding-top: 4.0625rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-xl-70 {
    padding: 4.375rem !important;
  }
  .pt-xl-70 {
    padding-top: 4.375rem !important;
  }
  .pr-xl-70 {
    padding-right: 4.375rem !important;
  }
  .pb-xl-70 {
    padding-bottom: 4.375rem !important;
  }
  .pl-xl-70 {
    padding-left: 4.375rem !important;
  }
  .px-xl-70 {
    padding-left: 4.375rem !important;
    padding-right: 4.375rem !important;
  }
  .py-xl-70 {
    padding-bottom: 4.375rem !important;
    padding-top: 4.375rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-xl-75 {
    padding: 4.6875rem !important;
  }
  .pt-xl-75 {
    padding-top: 4.6875rem !important;
  }
  .pr-xl-75 {
    padding-right: 4.6875rem !important;
  }
  .pb-xl-75 {
    padding-bottom: 4.6875rem !important;
  }
  .pl-xl-75 {
    padding-left: 4.6875rem !important;
  }
  .px-xl-75 {
    padding-left: 4.6875rem !important;
    padding-right: 4.6875rem !important;
  }
  .py-xl-75 {
    padding-bottom: 4.6875rem !important;
    padding-top: 4.6875rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-xl-80 {
    padding: 5rem !important;
  }
  .pt-xl-80 {
    padding-top: 5rem !important;
  }
  .pr-xl-80 {
    padding-right: 5rem !important;
  }
  .pb-xl-80 {
    padding-bottom: 5rem !important;
  }
  .pl-xl-80 {
    padding-left: 5rem !important;
  }
  .px-xl-80 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .py-xl-80 {
    padding-bottom: 5rem !important;
    padding-top: 5rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-xl-85 {
    padding: 5.3125rem !important;
  }
  .pt-xl-85 {
    padding-top: 5.3125rem !important;
  }
  .pr-xl-85 {
    padding-right: 5.3125rem !important;
  }
  .pb-xl-85 {
    padding-bottom: 5.3125rem !important;
  }
  .pl-xl-85 {
    padding-left: 5.3125rem !important;
  }
  .px-xl-85 {
    padding-left: 5.3125rem !important;
    padding-right: 5.3125rem !important;
  }
  .py-xl-85 {
    padding-bottom: 5.3125rem !important;
    padding-top: 5.3125rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-xl-90 {
    padding: 5.625rem !important;
  }
  .pt-xl-90 {
    padding-top: 5.625rem !important;
  }
  .pr-xl-90 {
    padding-right: 5.625rem !important;
  }
  .pb-xl-90 {
    padding-bottom: 5.625rem !important;
  }
  .pl-xl-90 {
    padding-left: 5.625rem !important;
  }
  .px-xl-90 {
    padding-left: 5.625rem !important;
    padding-right: 5.625rem !important;
  }
  .py-xl-90 {
    padding-bottom: 5.625rem !important;
    padding-top: 5.625rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-xl-95 {
    padding: 5.9375rem !important;
  }
  .pt-xl-95 {
    padding-top: 5.9375rem !important;
  }
  .pr-xl-95 {
    padding-right: 5.9375rem !important;
  }
  .pb-xl-95 {
    padding-bottom: 5.9375rem !important;
  }
  .pl-xl-95 {
    padding-left: 5.9375rem !important;
  }
  .px-xl-95 {
    padding-left: 5.9375rem !important;
    padding-right: 5.9375rem !important;
  }
  .py-xl-95 {
    padding-bottom: 5.9375rem !important;
    padding-top: 5.9375rem !important;
  }
  /* stylelint-enable */
  /* stylelint-disable declaration-colon-space-after */
  .p-xl-100 {
    padding: 6.25rem !important;
  }
  .pt-xl-100 {
    padding-top: 6.25rem !important;
  }
  .pr-xl-100 {
    padding-right: 6.25rem !important;
  }
  .pb-xl-100 {
    padding-bottom: 6.25rem !important;
  }
  .pl-xl-100 {
    padding-left: 6.25rem !important;
  }
  .px-xl-100 {
    padding-left: 6.25rem !important;
    padding-right: 6.25rem !important;
  }
  .py-xl-100 {
    padding-bottom: 6.25rem !important;
    padding-top: 6.25rem !important;
  }
  /* stylelint-enable */
}

/*
  _colorStyle.scss
  Update: 2018/01/31
*/
.text-default {
  color: #262626;
}

.btn-default {
  background-color: #262626;
  border-color: #262626;
  color: #fff;
}

.btn-default:hover {
  background-color: #131313;
  border-color: #131313;
  color: #fff;
}

.btn-default:focus, .btn-default.focus {
  background-color: #131313;
  border-color: #131313;
  box-shadow: 0 0 0 0.2rem rgba(71, 71, 71, 0.5);
  color: #fff;
}

.btn-default.disabled, .btn-default:disabled {
  background-color: #262626;
  border-color: #262626;
  color: #fff;
}

.btn-default:not(:disabled):not(.disabled):active, .btn-default:not(:disabled):not(.disabled).active,
.show > .btn-default.dropdown-toggle {
  background-color: #0d0d0d;
  border-color: #060606;
  color: #fff;
}

.btn-default:not(:disabled):not(.disabled):active:focus, .btn-default:not(:disabled):not(.disabled).active:focus,
.show > .btn-default.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 71, 71, 0.5);
}

.btn-outline-default {
  background-color: transparent;
  background-image: none;
  border-color: #262626;
  color: #262626;
}

.btn-outline-default:not(.touchend):hover {
  background-color: #262626;
  border-color: #262626;
  color: #fff;
}

.btn-outline-default:not(.touchend):focus, .btn-outline-default:not(.touchend).focus {
  box-shadow: 0 0 0 2px rgba(38, 38, 38, 0.5);
}

.btn-outline-default:not(.touchend).disabled, .btn-outline-default:not(.touchend):disabled {
  background-color: transparent;
  color: #262626;
}

.btn-outline-default:not(.touchend):active, .btn-outline-default:not(.touchend).active,
.show > .btn-outline-default:not(.touchend).dropdown-toggle {
  background-color: #262626;
  border-color: #262626;
  color: #fff;
}

.badge-default {
  background-color: #262626;
  color: #fff;
}

a.badge-default:hover, a.badge-default:focus {
  background-color: #0d0d0d;
  color: #fff;
}

a.badge-default:focus, a.badge-default.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
  outline: 0;
}

.bg-base {
  background-color: #ffffff !important;
}

a.bg-base:hover, a.bg-base:focus,
button.bg-base:hover,
button.bg-base:focus {
  background-color: #e6e6e6 !important;
}

.text-base {
  color: #ffffff;
}

.btn-base {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #212529;
}

.btn-base:hover {
  background-color: #ececec;
  border-color: #ececec;
  color: #212529;
}

.btn-base:focus, .btn-base.focus {
  background-color: #ececec;
  border-color: #ececec;
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
  color: #212529;
}

.btn-base.disabled, .btn-base:disabled {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #212529;
}

.btn-base:not(:disabled):not(.disabled):active, .btn-base:not(:disabled):not(.disabled).active,
.show > .btn-base.dropdown-toggle {
  background-color: #e6e6e6;
  border-color: #dfdfdf;
  color: #212529;
}

.btn-base:not(:disabled):not(.disabled):active:focus, .btn-base:not(:disabled):not(.disabled).active:focus,
.show > .btn-base.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}

.btn-outline-base {
  border-color: #ffffff;
  color: #ffffff;
}

.btn-outline-base:hover {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #212529;
}

.btn-outline-base:focus, .btn-outline-base.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-base.disabled, .btn-outline-base:disabled {
  background-color: transparent;
  color: #ffffff;
}

.btn-outline-base:not(:disabled):not(.disabled):active, .btn-outline-base:not(:disabled):not(.disabled).active,
.show > .btn-outline-base.dropdown-toggle {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #212529;
}

.btn-outline-base:not(:disabled):not(.disabled):active:focus, .btn-outline-base:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-base.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.badge-base {
  background-color: #ffffff;
  color: #212529;
}

a.badge-base:hover, a.badge-base:focus {
  background-color: #e6e6e6;
  color: #212529;
}

a.badge-base:focus, a.badge-base.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
  outline: 0;
}

.bg-main {
  background-color: #60313b !important;
}

a.bg-main:hover, a.bg-main:focus,
button.bg-main:hover,
button.bg-main:focus {
  background-color: #3e2026 !important;
}

.text-main {
  color: #60313b;
}

.btn-main {
  background-color: #60313b;
  border-color: #60313b;
  color: #fff;
}

.btn-main:hover {
  background-color: #47242b;
  border-color: #47242b;
  color: #fff;
}

.btn-main:focus, .btn-main.focus {
  background-color: #47242b;
  border-color: #47242b;
  box-shadow: 0 0 0 0.2rem rgba(120, 80, 88, 0.5);
  color: #fff;
}

.btn-main.disabled, .btn-main:disabled {
  background-color: #60313b;
  border-color: #60313b;
  color: #fff;
}

.btn-main:not(:disabled):not(.disabled):active, .btn-main:not(:disabled):not(.disabled).active,
.show > .btn-main.dropdown-toggle {
  background-color: #3e2026;
  border-color: #361b21;
  color: #fff;
}

.btn-main:not(:disabled):not(.disabled):active:focus, .btn-main:not(:disabled):not(.disabled).active:focus,
.show > .btn-main.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(120, 80, 88, 0.5);
}

.btn-outline-main {
  border-color: #60313b;
  color: #60313b;
}

.btn-outline-main:hover {
  background-color: #60313b;
  border-color: #60313b;
  color: #fff;
}

.btn-outline-main:focus, .btn-outline-main.focus {
  box-shadow: 0 0 0 0.2rem rgba(96, 49, 59, 0.5);
}

.btn-outline-main.disabled, .btn-outline-main:disabled {
  background-color: transparent;
  color: #60313b;
}

.btn-outline-main:not(:disabled):not(.disabled):active, .btn-outline-main:not(:disabled):not(.disabled).active,
.show > .btn-outline-main.dropdown-toggle {
  background-color: #60313b;
  border-color: #60313b;
  color: #fff;
}

.btn-outline-main:not(:disabled):not(.disabled):active:focus, .btn-outline-main:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-main.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(96, 49, 59, 0.5);
}

.badge-main {
  background-color: #60313b;
  color: #fff;
}

a.badge-main:hover, a.badge-main:focus {
  background-color: #3e2026;
  color: #fff;
}

a.badge-main:focus, a.badge-main.focus {
  box-shadow: 0 0 0 0.2rem rgba(96, 49, 59, 0.5);
  outline: 0;
}

.bg-accent {
  background-color: #484800 !important;
}

a.bg-accent:hover, a.bg-accent:focus,
button.bg-accent:hover,
button.bg-accent:focus {
  background-color: #151500 !important;
}

.text-accent {
  color: #484800;
}

.btn-accent {
  background-color: #484800;
  border-color: #484800;
  color: #fff;
}

.btn-accent:hover {
  background-color: #222200;
  border-color: #222200;
  color: #fff;
}

.btn-accent:focus, .btn-accent.focus {
  background-color: #222200;
  border-color: #222200;
  box-shadow: 0 0 0 0.2rem rgba(99, 99, 38, 0.5);
  color: #fff;
}

.btn-accent.disabled, .btn-accent:disabled {
  background-color: #484800;
  border-color: #484800;
  color: #fff;
}

.btn-accent:not(:disabled):not(.disabled):active, .btn-accent:not(:disabled):not(.disabled).active,
.show > .btn-accent.dropdown-toggle {
  background-color: #151500;
  border-color: #080800;
  color: #fff;
}

.btn-accent:not(:disabled):not(.disabled):active:focus, .btn-accent:not(:disabled):not(.disabled).active:focus,
.show > .btn-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(99, 99, 38, 0.5);
}

.btn-outline-accent {
  border-color: #484800;
  color: #484800;
}

.btn-outline-accent:hover {
  background-color: #484800;
  border-color: #484800;
  color: #fff;
}

.btn-outline-accent:focus, .btn-outline-accent.focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 72, 0, 0.5);
}

.btn-outline-accent.disabled, .btn-outline-accent:disabled {
  background-color: transparent;
  color: #484800;
}

.btn-outline-accent:not(:disabled):not(.disabled):active, .btn-outline-accent:not(:disabled):not(.disabled).active,
.show > .btn-outline-accent.dropdown-toggle {
  background-color: #484800;
  border-color: #484800;
  color: #fff;
}

.btn-outline-accent:not(:disabled):not(.disabled):active:focus, .btn-outline-accent:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 72, 0, 0.5);
}

.badge-accent {
  background-color: #484800;
  color: #fff;
}

a.badge-accent:hover, a.badge-accent:focus {
  background-color: #151500;
  color: #fff;
}

a.badge-accent:focus, a.badge-accent.focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 72, 0, 0.5);
  outline: 0;
}

.text-red {
  color: #C00;
}

/*
  _pageTopShow.scss
  Update: 2018/01/24
*/
.pagetop-show {
  display: none;
}

/*
  _pgeTopFixed.scss
  Update: 2018/01/24
*/
.pagetop-wrap {
  position: relative;
}

.pagetop-target {
  bottom: 20px;
  position: fixed;
}

.pagetop-target.pagetop-absolute {
  bottom: 20px;
  position: absolute;
}

/* ****************************************************************
layout.scss
Update: 2018/01/23
*******************************************************************/
/* デフォルト bootstrap4
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;
*/
.header {
  background-color: #262626;
  color: #fff;
}

.header a {
  color: #fff;
}

.header a:focus, .header a:hover {
  opacity: .7;
  text-decoration: none;
}

@media (min-width: 576px) {
  .home-header.container,
  .sub-header.container {
    max-width: 960px !important;
  }
}

.footer {
  background-color: #262626;
  color: #fff;
  margin-top: 4rem;
}

.footer .ft-cap {
  background-color: #484800;
}

.footer .ft-contents {
  padding: 1rem 0;
}

@media (min-width: 576px) {
  .footer .ft-contents {
    padding: 2rem 0 1rem;
  }
}

.footer a {
  color: #fff;
}

.footer a:focus, .footer a:hover {
  opacity: .7;
  text-decoration: none;
}

.footer .page-top {
  color: #757575;
  font-weight: 700;
  position: absolute;
  right: 15px;
  top: -3em;
}

@media (min-width: 576px) {
  .footer .page-top {
    right: 0;
  }
}

@media (min-width: 768px) {
  .container.home-container {
    max-width: 630px;
  }
  .container.sub-container {
    max-width: 730px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 990px;
  }
  .container.home-container, .container.sub-container {
    max-width: 990px;
  }
}

.home-layout-main {
  margin-bottom: 40px;
}

@media (min-width: 992px) {
  .home-layout-main {
    width: 600px;
  }
}

.home-layout-side {
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  max-width: 330px;
}

@media (min-width: 992px) {
  .home-layout-side {
    margin-right: 0;
    width: 330px;
  }
}

@media (min-width: 992px) {
  .sub-layout-main {
    width: 700px;
  }
}

@media (min-width: 992px) {
  .sub-layout-side {
    margin-left: auto;
    width: 200px;
  }
}

@media (min-width: 992px) {
  .side-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 30px;
  }
}

.page-section {
  margin-bottom: 60px;
}

@media (min-width: 768px) {
  .page-section {
    margin-bottom: 80px;
  }
}

.page-section-inner {
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .page-section-inner {
    margin-bottom: 60px;
  }
}

/* ****************************************************************
client.scss
Update: 2018/01/23
*******************************************************************/
@media (min-width: 768px) {
  .d-sm-none {
    display: none;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .d-xs-none {
    display: none;
  }
}

p {
  font-feature-settings: "palt";
  text-align: justify;
}

.txt-point {
  font-size: 1.125rem;
}

@media (min-width: 768px) {
  .txt-point {
    font-size: 1.5rem;
  }
}

.bg-gradient {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.7) 100%);
}

.indent-05 {
  margin-left: .5em;
  text-indent: -.5em;
}

.indent-01 {
  margin-left: 1em;
  text-indent: -1em;
}

.p-relative {
  position: relative;
}

.on_off:not(.touchend):focus img, .on_off:not(.touchend):hover img {
  opacity: .8;
}

.btn-shop-link-home,
.btn-shop-link-sub,
.btn-blog-link {
  background: linear-gradient(to bottom, #bc8a9b 0%, #956472 50%, #63333f 100%);
  border: 2px solid #CBCBCB;
  border-radius: 5px;
  color: #fff;
  font-weight: 700;
  line-height: 1;
  padding: 1em .25em;
  text-align: center;
}

.btn-shop-link-home:not(.touchend):focus, .btn-shop-link-home:not(.touchend):hover,
.btn-shop-link-sub:not(.touchend):focus,
.btn-shop-link-sub:not(.touchend):hover,
.btn-blog-link:not(.touchend):focus,
.btn-blog-link:not(.touchend):hover {
  background: linear-gradient(to bottom, #63333f 0%, #956472 50%, #bc8a9b 100%);
  color: #fff;
}

.btn-shop-link-home {
  font-size: 1.25rem;
}

.btn-shop-link-sub {
  font-size: 1rem;
  width: 100%;
}

.btn-blog-link {
  font-size: 1.125rem;
  max-width: 200px;
  width: 100%;
}

.btn-blog-link .oi {
  font-size: 75%;
  opacity: .7;
}

.embed-responsive.embed-responsive-9by16 {
  background-position: center center;
  background-size: cover;
}

.embed-responsive.embed-responsive-9by16::before {
  padding-top: 180%;
}

.pswp-youtube {
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  width: calc(100vh / 2.2);
}

.pswp-youtube-btn {
  display: block;
}

.feature-palt {
  font-feature-settings: "palt";
}

.rsMenu-compliance {
  z-index: 1400;
}

.rs-nav-wrap {
  color: #fff;
}

.rs-nav-wrap .rs-title {
  position: relative;
}

.rs-nav-wrap .rs-title::after {
  /* stylelint-disable */
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%);
  bottom: 0;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  /* stylelint-enable */
}

.rs-nav-wrap .rs-title .rs-title-text {
  color: #990;
}

.rs-nav-wrap a {
  color: #fff;
}

.rs-nav-list ul li {
  margin-bottom: 2px;
}

.rs-nav-list a {
  border-bottom: 1px dashed #e6e6e6;
  display: block;
  padding: 10px 0;
}

.rs-nav-list a .oi-caret-right {
  color: #e6e6e6;
}

.rs-nav-list a .oi-external-link {
  color: #e6e6e6;
  font-size: 75%;
}

.rs-nav-list a:hover, .rs-nav-list a:focus {
  text-decoration: none;
}

.rs-nav-list a:not(.touchend):focus, .rs-nav-list a:not(.touchend):hover {
  color: #990;
}

.rs-nav-list a:not(.touchend):focus .oi-caret-right, .rs-nav-list a:not(.touchend):hover .oi-caret-right {
  color: #990;
}

.rs-nav-list a:not(.touchend):focus .oi-external-link, .rs-nav-list a:not(.touchend):hover .oi-external-link {
  color: #e6e6e6;
}

.rs-tel-mobile {
  line-height: 1;
}

.rs-tel-mobile a {
  color: #fff;
  display: block;
}

.rs-tel-mobile .hd-tel span {
  letter-spacing: .03em;
}

.rs-tel-mobile .hd-tel a {
  font-size: 1.5rem;
}

.rs-tel-mobile .hd-tel .hd-link-icon {
  width: 1em;
}

.rs-tel-mobile .hd-mobile a {
  font-size: 1.5rem;
}

.rs-tel-mobile .hd-mobile .hd-link-icon {
  width: 1em;
}

@media (min-width: 992px) {
  .hd-logo-links {
    position: relative;
  }
  .hd-logo-links:not(.touchend):focus, .hd-logo-links:not(.touchend):hover {
    font-size: 14px;
    line-height: 1;
  }
  .hd-logo-links:not(.touchend):focus::before, .hd-logo-links:not(.touchend):hover::before {
    bottom: 2px;
    box-sizing: content-box;
    content: "\e02e";
    display: block;
    font-family: Icons, sans-serif;
    font-size: 75%;
    font-style: normal;
    position: absolute;
    right: -15px;
    text-align: center;
    width: 1em;
  }
  .hd-logo-links:not(.touchend):focus::after, .hd-logo-links:not(.touchend):hover::after {
    bottom: 1px;
    content: "トップページへ";
    display: block;
    font-weight: 700;
    position: absolute;
    right: -15px;
    transform: translateX(100%);
  }
}

.hd-title-cap {
  font-size: 0.875rem;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .hd-title-cap {
    font-size: 0.75rem;
  }
}

.hd-tel-mobile {
  line-height: 1;
}

.hd-tel-mobile::after {
  clear: both;
  content: "";
  display: block;
}

.hd-tel-mobile li {
  float: left;
  width: 100%;
}

@media (min-width: 576px) {
  .hd-tel-mobile li {
    width: auto;
  }
}

.hd-tel-mobile li:not(:last-child) {
  margin-right: .5em;
}

.hd-tel-mobile a {
  color: #fff;
  display: block;
}

.hd-tel-mobile .hd-tel span {
  letter-spacing: .03em;
}

.hd-tel-mobile .hd-tel a {
  font-size: 1.5rem;
}

@media (min-width: 768px) {
  .hd-tel-mobile .hd-tel a {
    font-size: 1.6875rem;
  }
}

@media (min-width: 992px) {
  .hd-tel-mobile .hd-tel a {
    font-size: 2.125rem;
  }
}

.hd-tel-mobile .hd-tel .hd-link-icon {
  width: 1em;
}

.hd-tel-mobile .hd-mobile a {
  font-size: 1.5rem;
}

@media (min-width: 768px) {
  .hd-tel-mobile .hd-mobile a {
    font-size: 1.625rem;
    padding-top: .03125rem;
  }
}

@media (min-width: 992px) {
  .hd-tel-mobile .hd-mobile a {
    font-size: 1.75rem;
    padding-top: .1875rem;
  }
}

.hd-tel-mobile .hd-mobile .hd-link-icon {
  width: 1em;
}

.header-title-cap {
  font-size: 13px;
}

.header-title {
  font-size: 26px;
}

.g-nav {
  background-color: #60313b;
}

.g-nav .container-nav {
  margin-left: auto;
  margin-right: auto;
  max-width: 960px;
  padding: 0 10px;
  width: 100%;
}

.g-nav .g-nav-links {
  color: #fff;
  display: block;
  font-weight: 700;
  padding: 1.5em 0;
  position: relative;
  text-align: center;
  width: 100%;
}

.g-nav .g-nav-links:focus, .g-nav .g-nav-links:hover {
  text-decoration: none;
}

.g-nav .g-nav-links:not(.touchend):focus, .g-nav .g-nav-links:not(.touchend):hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.g-nav .g-nav-links:not(.touchend):focus::before, .g-nav .g-nav-links:not(.touchend):hover::before {
  background-color: rgba(255, 255, 255, 0.7);
  bottom: 0;
  content: "";
  height: 3px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
}

@media (min-width: 768px) {
  .g-nav .g-nav-links {
    font-size: 0.875rem;
  }
}

@media (min-width: 992px) {
  .g-nav .g-nav-links {
    font-size: 1.0625rem;
  }
}

.g-nav ul {
  display: table;
  width: 100%;
}

.g-nav ul li {
  display: table-cell;
  vertical-align: middle;
}

.g-nav ul li:not(:last-child) .g-nav-links::after {
  border-right: 1px dashed rgba(255, 255, 255, 0.9);
  content: "";
  height: 1em;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
}

.ft-title-01 {
  font-size: 0.9375rem;
  letter-spacing: .03em;
  line-height: 1.5;
  margin: 0;
  padding: .6em 0 .5em;
}

@media (min-width: 576px) {
  .ft-title-01 {
    font-size: 1.25rem;
    letter-spacing: .06em;
    padding: .75em 0 .65em;
  }
}

@media (min-width: 992px) {
  .ft-title-01 {
    letter-spacing: .08em;
    line-height: 1;
    padding: 1em 0;
  }
}

.ft-tel-mobile {
  margin-right: 1rem;
}

.ft-tel-mobile .ft-tel {
  line-height: 1;
}

.ft-tel-mobile .ft-tel span {
  letter-spacing: .03em;
}

.ft-tel-mobile .ft-mobile {
  line-height: 1;
}

.ft-tel-mobile a {
  color: #fff;
  display: block;
}

.ft-tel-mobile li {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
  padding-bottom: 8px;
}

.ft-tel-mobile li:not(:last-child) {
  margin-bottom: 6px;
}

.open-24-box {
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.open-24-box .open-24-text {
  font-size: 0.75rem;
  padding: 0 .5em;
}

@media (min-width: 576px) {
  .open-24-box .open-24-text {
    font-size: 0.9375rem;
  }
}

.ft-address {
  font-size: 0.875rem;
}

@media (min-width: 576px) {
  .ft-address {
    font-size: 1rem;
  }
}

.copyright {
  text-align: right;
}

@media (min-width: 992px) {
  .copyright {
    bottom: 5px;
    position: absolute;
    right: 15px;
  }
}

.footer-cap-text {
  position: relative;
}

.footer-cap-text::after {
  /* stylelint-disable */
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%);
  bottom: 0;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  /* stylelint-enable */
}

.ft-list-links {
  padding: 1rem 0;
}

.ft-list-links ul {
  line-height: 1;
  margin-bottom: 0;
}

.ft-list-links ul li {
  margin-right: 1.5rem;
}

.ft-list-links a {
  display: block;
  font-size: 0.9375rem;
  margin-bottom: 1.25rem;
}

.ft-list-links a .oi-play-circle {
  color: #d4d4d4;
  font-size: 80%;
}

.ft-list-links a .oi-external-link {
  color: #a8a8a8;
  font-size: 60%;
}

@media (min-width: 576px) {
  .ft-list-links {
    padding: 0 0 1rem;
  }
}

@media (min-width: 992px) {
  .ft-list-links {
    margin-top: 1em;
    padding: 0;
  }
  .ft-list-links ul li {
    margin-right: 0;
  }
  .ft-list-links ul li:nth-child(1) {
    width: 220px;
  }
  .ft-list-links ul li:nth-child(2) {
    width: 140px;
  }
  .ft-list-links ul li:nth-child(3) {
    width: 100px;
  }
}

.home-header-photo {
  padding: 0 15px;
}

@media (min-width: 992px) {
  .home-header-photo {
    height: 400px;
    padding: 0;
  }
}

@media (min-width: 992px) {
  .home-select-banner {
    margin-top: -30px;
  }
}

.voice-main-title {
  font-size: 1.375rem;
}

@media (min-width: 576px) {
  .voice-main-title {
    font-size: 1.875rem;
  }
}

.voice-title {
  background-color: #D4C8CB;
  border-left: 10px solid #60313b;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.2;
  padding: 7px 2px 7px 10px;
}

.home-title-main {
  color: #C00;
  font-size: 1.375rem;
}

@media (min-width: 576px) {
  .home-title-main {
    font-size: 1.625rem;
  }
}

@media (min-width: 768px) {
  .home-title-main {
    font-size: 2rem;
  }
}

.home-menu-lists {
  margin-bottom: 2rem;
}

.home-menu-lists li {
  line-height: 1.2;
  margin-bottom: .75em;
  padding-left: 1em;
  padding-right: .5em;
  position: relative;
}

.home-menu-lists li::before {
  box-sizing: content-box;
  color: #60313b;
  content: "\e0a9";
  display: block;
  font-family: Icons, sans-serif;
  font-size: 70%;
  font-style: normal;
  left: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  width: 1em;
}

.home-menu-lists a {
  color: #000;
  display: block;
  font-feature-settings: "palt";
  font-size: 1.125rem;
  padding: 5px;
}

@media (min-width: 576px) {
  .home-menu-lists a {
    font-size: 1rem;
    padding: 0;
  }
}

.fb-home-wrap {
  height: 350px;
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  overflow: hidden;
}

@media (min-width: 576px) {
  .fb-home-wrap {
    height: 600px;
  }
}

@media (min-width: 992px) {
  .fb-home-wrap {
    margin-left: 0;
  }
}

.fb-sub-wrap {
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  min-height: 200px;
}

.form-mailer-wrap {
  height: 800px;
  overflow: hidden;
  width: 100%;
}

.blog-btn {
  background-color: #000;
  border: 1px solid #000;
  border-bottom: none;
  display: block;
  padding: 10px;
}

.blog-btn span {
  color: #ff0000;
}

.blog-btn .blog-title {
  color: #ff0000;
}

.blog-btn:focus, .blog-btn:hover {
  text-decoration: none;
}

.blog-btn:not(.touchend):focus, .blog-btn:not(.touchend):hover {
  background-color: #242424;
  color: #fff;
}

.blog-btn:not(.touchend):focus .blog-title, .blog-btn:not(.touchend):hover .blog-title {
  text-decoration: underline;
}

.feed-wrap {
  border: 1px solid #000;
  margin-bottom: 0;
  padding: 15px;
}

.feed-wrap li:not(:last-child) {
  margin-bottom: 1.5rem;
}

.feed-wrap .feed-link:focus, .feed-wrap .feed-link:hover {
  text-decoration: none;
}

.feed-wrap .feed-link:not(.touchend):focus .feed-img, .feed-wrap .feed-link:not(.touchend):hover .feed-img {
  opacity: .7;
}

.feed-wrap .feed-img {
  background-position: center center;
  background-size: cover;
  padding-top: 56.25%;
}

.feed-wrap .feed-title {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.4;
  margin-top: 2px;
}

.feed-wrap .feed-date {
  color: #262626;
  font-size: 0.75rem;
  margin-bottom: 0;
}

.feed-wrap .feed-description {
  color: #262626;
  font-size: 0.8125rem;
  margin-bottom: 0;
}

.side-sub-nav {
  display: none;
}

@media (min-width: 992px) {
  .side-sub-nav {
    display: block;
    margin-top: 42px;
  }
  .entrylist-side .side-sub-nav {
    margin-top: 0;
  }
}

.side-sub-nav .side-title {
  border-bottom: 6px solid #D6D2CF;
  font-size: 1.0625rem;
  font-weight: 700;
  padding-bottom: .5em;
}

.side-sub-nav .side-list {
  font-size: 1rem;
  margin-bottom: 4rem;
}

.side-sub-nav .side-list .oi {
  color: #60313b;
  font-size: 75%;
  left: 0;
  position: absolute;
  top: 51%;
  transform: translateY(-50%);
}

.side-sub-nav .side-list li {
  border-bottom: 1px dashed #CCC;
  padding-bottom: 1em;
  padding-top: 1em;
  position: relative;
}

.side-sub-nav .side-list a {
  color: #262626;
  display: inline-block;
  font-feature-settings: "palt";
  padding-left: 1.2em;
}

.side-sub-nav .side-list a:focus, .side-sub-nav .side-list a:hover {
  color: #CCC;
  text-decoration: none;
}

.page-title {
  border-bottom: 6px solid #CCC;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
  font-feature-settings: "palt";
  font-size: 1.875rem;
  font-weight: 700;
  margin-bottom: 1em;
  padding-bottom: .38em;
  padding-left: .5em;
  padding-top: 5px;
}

@media (min-width: 768px) {
  .page-title {
    font-size: 2.625rem;
  }
}

.section-title {
  color: #60313b;
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 1.5em;
  padding-top: 5px;
}

@media (min-width: 768px) {
  .section-title {
    font-size: 1.375rem;
    margin-bottom: 2em;
  }
}

.item-sec-title {
  border-bottom: 1px dotted #333;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  padding-bottom: .25rem;
}

.item-spec {
  background-color: #F3F3F3;
  line-height: 1.25;
  margin-bottom: 1.5rem;
  padding: 0 10px 8px;
}

.item-spec .spec-th,
.item-spec .spec-td {
  padding-top: 8px;
}

.item-spec .spec-th {
  font-size: 0.9375rem;
  font-weight: 700;
  padding-right: 1em;
  width: 65px;
}

.item-spec .spec-td {
  font-feature-settings: "palt";
  font-size: 0.9375rem;
}

.item-spec .d-table-row + .d-table-row .spec-th,
.item-spec .d-table-row + .d-table-row .spec-td {
  border-bottom: 1px solid #ccc;
  padding-bottom: 8px;
}

.item-spec .d-table-row:not(:last-of-type):first-of-type .spec-th,
.item-spec .d-table-row:not(:last-of-type):first-of-type .spec-td {
  border-bottom: 1px solid #ccc;
  padding-bottom: 8px;
}

.item-spec .d-table-row:not(:first-of-type):last-of-type .spec-th,
.item-spec .d-table-row:not(:first-of-type):last-of-type .spec-td {
  border-bottom: none;
  padding-bottom: 0;
}

.item-cap-list {
  font-size: 0.9375rem;
}

.item-cap-list li {
  font-feature-settings: "palt";
}

.item-price {
  border-bottom: #CCC solid 1px;
  line-height: 1;
  padding: 15px 0;
}

.item-price .item-price-title {
  font-size: 1rem;
  font-weight: 700;
}

.item-price .item-price-num {
  font-size: 1.5rem;
  font-weight: 700;
}

.item-price .item-price-before,
.item-price .item-price-tax {
  font-size: 0.875rem;
}

.item-price .item-100shiage {
  border: 2px solid #bbb;
  border-radius: 3px;
  color: #828282;
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1;
  padding: 2px 5px;
}

.item-price .item-price-shiage-num {
  font-size: 1.125rem;
  font-weight: 700;
}

.oogata-table {
  font-size: 0.9375rem;
}

.oogata-table th,
.oogata-table td {
  padding: 8px;
  text-align: center;
}

.oogata-table th {
  background-color: #f96;
}

.kinpou-table {
  font-size: 0.75rem;
}

.kinpou-table th,
.kinpou-table td {
  padding: 5px;
  text-align: center;
}

.kinpou-table th {
  background-color: #f96;
}

.kokusan-butsudan {
  color: #C00;
  font-size: 1.25rem;
}

@media (min-width: 768px) {
  .kokusan-butsudan {
    font-size: 1.375rem;
  }
}

.item-page .page-section {
  margin-bottom: 100px;
}

@media (min-width: 768px) {
  .item-page .page-section {
    margin-bottom: 80px;
  }
}

.shop-sec-title {
  background-color: #60313b;
  color: #fff;
  font-feature-settings: "palt";
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1.5em;
  padding: .75rem;
}

.shop-sec-title2 {
  color: #60313b;
  font-feature-settings: "palt";
  font-size: 1.625rem;
  font-weight: 700;
  margin-bottom: 1.5em;
  padding-top: 5px;
}

@media (min-width: 768px) {
  .shop-sec-title2 {
    margin-bottom: 2em;
  }
}

.shop-kumamon-title {
  color: #60313b;
  font-feature-settings: "palt";
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 1.5em;
  padding-top: 5px;
}

@media (min-width: 768px) {
  .shop-kumamon-title {
    font-size: 1.375rem;
    margin-bottom: 2em;
  }
}

.shop-price {
  border-bottom: #CCC solid 1px;
  line-height: 1;
  padding: 15px 0;
}

.shop-price .shop-price-title {
  font-size: 0.8125rem;
  font-weight: 700;
}

.shop-price .shop-price-num {
  font-size: 1.25rem;
  font-weight: 700;
}

.shop-price .shop-price-before,
.shop-price .shop-price-tax {
  font-size: 0.75rem;
}

.map-iframe {
  height: 250px;
}

@media (min-width: 576px) {
  .map-iframe {
    height: 450px;
  }
}

.shop-info-table tr:last-child td,
.shop-info-table tr:last-child th {
  border-bottom: 1px solid #dee2e6;
}

.shop-info-table th {
  vertical-align: middle;
  white-space: nowrap;
}

@media (min-width: 768px) {
  .shop-info-table th {
    width: 200px;
  }
}

.faq-sec-title {
  color: #60313b;
  font-feature-settings: "palt";
  font-size: 1.625rem;
  font-weight: 700;
  margin-bottom: 1.5em;
  padding-top: 5px;
}

@media (min-width: 768px) {
  .faq-sec-title {
    margin-bottom: 2em;
  }
}

.faq-list li {
  margin-bottom: 50px;
}

.title-faq {
  background: url("../images/faq/icon_q.gif") 0 center no-repeat;
  color: #60313b;
  font-size: 1.1875rem;
  font-weight: 700;
  padding: 10px 0 10px 45px;
  position: relative;
}

.title-faq::after {
  background-color: #60313b;
  bottom: -10px;
  content: "";
  height: 3px;
  left: 0;
  position: absolute;
  width: 100%;
}

.faq-answer {
  background: url("../images/faq/icon_a.gif") 0 0 no-repeat;
  margin: 30px 0 50px 0;
  min-height: 45px;
  padding-left: 45px;
}

.faq-size-table {
  max-width: 300px;
}

.faq-size-table th {
  background: #F3F3F3;
  text-align: center;
}

.faq-tel-box {
  border: 5px solid #60313b;
  border-radius: 10px;
  padding: 8px 15px 10px;
}

.faq-tel-box .faq-hr {
  border-top: 4px solid #ccc;
  margin-bottom: .5rem;
  margin-top: 1rem;
}

.faq-tel-box .tel-box-title {
  font-size: 1rem;
  line-height: 1.2;
}

.faq-tel-box .tel-box-title2 {
  font-size: 165%;
}

@media (min-width: 768px) {
  .faq-tel-box {
    border: 10px solid #60313b;
    padding: 17px 25px 20px;
  }
  .faq-tel-box .tel-box-title {
    font-size: 1.3125rem;
  }
}

.contact-sec-title {
  color: #60313b;
  font-feature-settings: "palt";
  font-size: 1.375rem;
  font-weight: 700;
  margin-bottom: .5em;
  padding-top: 5px;
}

.gallery-sec-title {
  color: #60313b;
  font-size: 1.375rem;
  font-weight: 700;
}

.gallery-list {
  padding: 1rem 0;
}

.gallery-list:not(:last-child) {
  border-bottom: 1px solid #ccc;
}

.gallery-list .echo-item {
  opacity: 0;
  transition: opacity 1s ease;
}

.gallery-list .echo-item.loaded {
  opacity: 1;
}

.jirei-title {
  color: #60313b;
  font-size: 1.375rem;
  font-weight: 700;
}

.jirei-kei {
  border-bottom: 1px dashed #ccc;
}

.jirei-box {
  font-size: 0.75rem;
}

.jirei-box a {
  display: block;
}

.jirei-box .gallery-figure {
  margin: 0 0 .5rem;
}

.jirei-box img {
  height: auto;
  width: 100%;
}

.jirei-box .echo-item {
  opacity: 0;
  transition: all 1s ease;
}

.jirei-box .echo-item.loaded {
  border: 1px solid #ccc;
  opacity: 1;
}

.jirei-box p {
  line-height: 1.2;
  margin-bottom: .5em;
  text-align: center;
}

/* ****************************************************************
pagenavi-css.scss

Default style for WP-PageNavi plugin
http://wordpress.org/extend/plugins/wp-pagenavi/

.wp-pagenavi {
  clear: both;
  a,
  span {
    text-decoration: none;
    border: 1px solid #BFBFBF;
    padding: 3px 5px;
    margin: 2px;
  }
  a:hover,
  .current {
    border-color: #000;
  }
  .current {
    font-weight: 700;
  }
}

Update: 2019/02/01
*******************************************************************/
.wp-pagenavi {
  display: inline-flex;
}

.wp-pagenavi .pages {
  display: none;
}

.wp-pagenavi a:first-child,
.wp-pagenavi > span:first-child {
  border-bottom-left-radius: .25rem;
  border-top-left-radius: .25rem;
  margin-left: 0;
}

.wp-pagenavi a:last-child,
.wp-pagenavi > span:last-child {
  border-bottom-right-radius: .25rem;
  border-top-right-radius: .25rem;
}

.wp-pagenavi a,
.wp-pagenavi > span {
  background-color: #fff;
  border: 1px solid #dee2e6;
  color: #262626;
  display: block;
  line-height: 1.25;
  margin-left: -1px;
  padding: .4rem .8rem;
  text-decoration: none;
}

.wp-pagenavi a:focus, .wp-pagenavi a:hover {
  background-color: #e9ecef;
  border: 1px solid #e9ecef;
}

.wp-pagenavi > span {
  cursor: default;
}

.wp-pagenavi .current {
  background-color: #262626;
  border-color: #262626;
  color: #fff;
  z-index: 1;
}

.wp-pagenavi .oi {
  font-size: .8em;
  top: .05em;
}

@media (max-width: 575.98px) {
  .wp-pagenavi {
    font-size: 90%;
  }
  .wp-pagenavi a,
  .wp-pagenavi > span {
    padding: .4rem .7rem;
  }
}

.type-post p {
  font-size: 1.125rem;
}

@media (max-width: 575.98px) {
  .type-post p {
    font-size: 1.375rem;
  }
}

.wp-block-image img {
  height: auto;
  max-width: 100%;
}
/*# sourceMappingURL=common.css.map */